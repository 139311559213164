<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-list">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="candidate-list__title">Lista de vacantes</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pl-0 pr-0 text-left">
          <v-autocomplete
            item-text="pais"
            item-value="pais"
            v-model="listCountrySelected"
            :items="listCountry"
            label="Filtrar por país"
            @change="getVacancies"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="pl-0 pr-0" cols="12">
          <v-data-table
            style="
              border-radius: 15px;
              height: 100%;
              border: solid 1.2px #e0e0e1;
            "
            :search="search"
            :loading="loading.table"
            :items-per-page="10"
            :headers="headersVacancies"
            :items="listVacancies"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15] }"
            class="elevation-0 vacancies__table"
          >
            <template v-slot:no-results>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 forms__alert-completed"
              >
                <b>No se encontraron resultados.</b>
              </v-alert>
            </template>
            <template class="pl-0 pr-0" v-slot:no-data>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 forms__alert-completed"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <template v-slot:item.title="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.title }}
                </p>
              </div>
            </template>
            <template v-slot:item.id_usuario="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ findNameWorker(item.id_usuario) }}
                </p>
              </div>
            </template>
            <template v-slot:item.numero_de_vacantes="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.numero_de_vacantes }}
                </p>
              </div>
            </template>
            <template v-slot:item.status_name="{ item }">
              <v-chip
                v-if="item.status_name"
                :color="getColorStatus(item.status_name)"
                dark
                small
              >
                {{ item.status_name }}
              </v-chip>
            </template>
            <template v-slot:item.post_img="{ item }">
              <img width="70" height="50" class="mt-2" :src="item.post_img" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    @click="goDeteils(item)"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    icon
                  >
                  <v-icon
                    :style="{
                      color: hover ? '#324CA1' : '#466BE3',
                    }"
                    small
                  >
                    mdi-account
                  </v-icon>
                  </v-btn>
                </template>
                <span> Ver candidatos</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
export default {
  mixins: [color],
  data() {
    return {
      listVacancies: [],
      vacancies: null,
      listCandidate: [],
      search: 0,
      loading: {
        table: false,
      },
      headersVacancies: [
        {
          text: "Imagen",
          value: "post_img",
          align: "center",
          class: "vacancies__header",
        },
        {
          text: "Título de vacante",
          value: "post_title",
          align: "start",
          class: "vacancies__header",
          sortable: false,
        },
        {
          text: "País de la vacante",
          value: "pais",
          align: "start",
          class: "vacancies__header",
          sortable: false,
        },
        {
          text: "Creador de la vacante",
          value: "id_usuario",
          align: "start",
          class: "vacancies__header",
          sortable: false,
        },
        {
          text: "Fecha de creación",
          value: "post_date",
          align: "start",
          class: "vacancies__header",
          sortable: false,
        },
        {
          text: "ID vacante",
          value: "post_id",
          align: "center",
          class: "document__header",
        },
        {
          text: "Estado",
          value: "status_name",
          align: "center",
          class: "vacancies__header",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "vacancies__header",
          sortable: false,
        },
      ],
      modal: {
        info: false,
      },
      dates: [],
      menu: false,
      maxDate: new Date().toISOString().substr(0, 10),
      id_usuario: 0,
      director: false,
      listUsers: [],
      token:"",
      listCountrySelected:"",
      listCountry:[]
    };
  },
  methods: {
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      this.listCountrys();
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    goDeteils(data) {
      this.$router.push({
        name: "allCandidatesList",
        params: { id: data.post_id },
      });
    },
    getVacancies() {
      this.loading.table = true;
      var data = new FormData();
      const pais = this.listCountrySelected === 'Todos los países' ? '' : this.listCountrySelected;
      data.append('pais', pais);
      data.append("id_usuario", this.id_usuario);
      data.append("director", "true");
      Api.Selection()
        .getVacancies(data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading.table = false;
          this.listVacancies = data;
          for (let i = 0; i < this.listVacancies.length; i++) {
            var status = "";
            if (this.listVacancies[i].post_status == "draft") {
              status = "Borrador";
            }
            if (this.listVacancies[i].post_status == "publish") {
              status = "Publicado";
            }
            this.listVacancies[i].status_name = status;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    listCountrys() {
      Api.Selection()
        .getCountrySelection(this.token)
        .then((res) => {
          console.log(res.data.data)
          this.listCountry=res.data.data;
          this.listCountry.unshift({ pais: 'Todos los países' }); // Agrega la opción vacía
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dataUser() {
      if (this.$store.getters.getUser) {
        this.id_usuario = this.$store.getters.getUser.id;
        this.getVacancies();
      }
    },
    findNameWorker(id_usuario) {
      const user = this.listUsers.find((item) => item.id == id_usuario);
      return user
        ? user.full_name.replace(/\b\w/g, (char) => char.toUpperCase())
        : null;
    },
    getAllUser() {
      if (this.$store.getters.getAllUsers) {
        this.listUsers = this.$store.getters.getAllUsers;
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  created() {
    this.getToken();
    this.dataUser();
    this.getAllUser();
  },
  watch: {
    "$store.state.user": {
      handler(newValue, oldValue) {
        this.dataUser();
      },
    },
    "$store.state.allUsers": {
      handler(newValue, oldValue) {
        this.getAllUser();
      },
    },
  },
};
</script>

<style lang="less">
@import "./../../assets/css/main.less";
.candidate-list__table {
  height: 780px;
  position: relative;
}

.vacancies__table {
  height: 600px;
  position: relative;
}

.candidate-list__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}
.candidate-list__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}
.candidate-list__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}
.candidate-list__header {
  color: #353535 !important;
}

.candidate-list__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-list__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}
</style>
