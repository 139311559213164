var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-list"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-0 pr-0 text-left",attrs:{"cols":"12"}},[_c('h1',{staticClass:"candidate-list__title"},[_vm._v("Lista de vacantes")])])],1),_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-0 text-left",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"item-text":"pais","item-value":"pais","items":_vm.listCountry,"label":"Filtrar por país"},on:{"change":_vm.getVacancies},model:{value:(_vm.listCountrySelected),callback:function ($$v) {_vm.listCountrySelected=$$v},expression:"listCountrySelected"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 vacancies__table",staticStyle:{"border-radius":"15px","height":"100%","border":"solid 1.2px #e0e0e1"},attrs:{"search":_vm.search,"loading":_vm.loading.table,"items-per-page":10,"headers":_vm.headersVacancies,"items":_vm.listVacancies,"footer-props":{ itemsPerPageOptions: [5, 10, 15] }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"mt-3 forms__alert-completed",attrs:{"text":"","dense":"","type":"info"}},[_c('b',[_vm._v("No se encontraron resultados.")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-3 forms__alert-completed",attrs:{"text":"","dense":"","type":"info"}},[_c('b',[_vm._v("No se encontró información.")])])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"#214484","height":5,"indeterminate":"","stream":""}})]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.title)+" ")])])]}},{key:"item.id_usuario",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(_vm.findNameWorker(item.id_usuario))+" ")])])]}},{key:"item.numero_de_vacantes",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.numero_de_vacantes)+" ")])])]}},{key:"item.status_name",fn:function(ref){
var item = ref.item;
return [(item.status_name)?_c('v-chip',{attrs:{"color":_vm.getColorStatus(item.status_name),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status_name)+" ")]):_vm._e()]}},{key:"item.post_img",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mt-2",attrs:{"width":"70","height":"50","src":item.post_img}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","icon":""},on:{"click":function($event){return _vm.goDeteils(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{style:({
                    color: _vm.hover ? '#324CA1' : '#466BE3',
                  }),attrs:{"small":""}},[_vm._v(" mdi-account ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Ver candidatos")])])]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }