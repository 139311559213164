<template>
  <div>
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-list">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="candidate-list__title">Lista de candidatos</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-btn
            class="mt-3"
            color="#466BE3"
            rounded
            dark
            @click="download"
            block
          >
            <strong>Descargar cantidatos </strong>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            class="mt-3"
            color="#466BE3"
            rounded
            dark
            @click="goBack"
            block
          >
            <strong>Volver </strong>
          </v-btn>
        </v-col>
        <v-col class="pl-0 pr-0" cols="12">
          <v-data-table            
            style="
              border-radius: 15px;
              height: 100%;
              border: solid 1.2px #e0e0e1;
            "
            :loading="loading.table"
            :items-per-page="15"
            :headers="headers"
            :items="listCandidate"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15] }"
            class="elevation-1 candidate-list__table"
          >
            <template v-slot:no-results>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 forms__alert-completed"
              >
                <b>No se encontraron resultados.</b>
              </v-alert>
            </template>
            <template class="pl-0 pr-0" v-slot:no-data>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 forms__alert-completed"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <template v-slot:item.title="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.title }}
                </p>
              </div>
            </template>
            <template v-slot:item.numero_de_vacantes="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.numero_de_vacantes }}
                </p>
              </div>
            </template>
            <template v-slot:item.origen="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.origen }}
                </p>
              </div>
            </template>
            <template v-slot:item.status_name="{ item }">
              <v-chip
                v-if="item.status_name"
                :color="getColorStatus(item.status_name)"
                dark
                small
              >
                {{ item.status_name }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-hover v-slot="{ hover }">
                <v-btn fab icon small @click="goDeteils(item)">
                  <v-icon
                    :style="{
                      color: hover ? '#324CA1' : '#466BE3',
                    }"
                    small
                  >
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
export default {
  mixins: [color],
  data() {
    return {
      listVacancies: [],
      vacancies: null,
      info: {
        post_title: "",
        tipo_de_documento: "",
        numero_de_documento: "",
        fecha_expedicion: "",
        correo_electronico: "",
        telefono: "",
        fecha_nacimiento: "",
        hoja_de_vida: "",
        primera_vez_en_brm: "",
        post_date: "",
        tratamiento_de_datos: "",
      },
      listCandidate: [],
      search: 0,
      loading: {
        table: false,
      },
      headers: [
        {
          text: "ID vacante",
          value: "idvacante",
          align: "center",
          class: "candidate-list__header",
          sortable: false,
        },
        {
          text: "Nombre del candidato",
          value: "post_title",
          align: "start",
          class: "candidate-list__header",
          sortable: false,
        },
        {
          text: "Número de documento",
          value: "numero_de_documento",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Correo electrónico",
          value: "correo_electronico",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Teléfono",
          value: "telefono",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Vacante",
          value: "vacante",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "País vacante",
          value: "pais_vacante",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Origen",
          value: "origen",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Fecha de creación",
          value: "post_date",
          align: "center",
          class: "candidate-list__header",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "candidate-list__header",
          sortable: false,
        },
      ],
      modal: {
        info: false,
      },
      dates: [],
      menu: false,
      maxDate: new Date().toISOString().substr(0, 10),
      id_usuario: 0,
      director: false,
      listUsers: [],
      postId:0
    };
  },
  methods: {
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    goDeteils(data) {
      localStorage.setItem("infoCandidate", JSON.stringify(data));
      this.$router.push({
        name: "candidateDetails",
        params: { id: data.post_id },
      });
    },
    downloadCV(url) {
      window.open(url, "_blank");
    },
    openModal(data) {
      this.modal.info = true;
      this.info = {
        post_title: data.post_title,
        tipo_de_documento: data.tipo_de_documento,
        numero_de_documento: data.numero_de_documento,
        fecha_expedicion: data.fecha_expedicion,
        correo_electronico: data.correo_electronico,
        telefono: data.telefono,
        fecha_nacimiento: data.fecha_nacimiento,
        hoja_de_vida: data.hoja_de_vida,
        primera_vez_en_brm: data.primera_vez_en_brm,
        post_date: data.post_date,
        tratamiento_de_datos: data.tratamiento_de_datos,
      };
    },
    download() {
      this.loading.table = true;
      this.search = this.search === null ? 0 : this.search;
      var data = new FormData();
      const id_usuario = this.$store.getters.getUser.id;

      data.append("created_at", this.dates.join(","));
      data.append("idVacante", this.search);
      data.append("id_usuario", id_usuario);
      data.append("director", "true");
      Api.Selection()
        .downloadCandidates(data)
        .then((res) => res.text())
        .then((data) => {
          const blob = new Blob([data], {
            type: "data:text/csv;charset=utf-8,",
          });
          const blobURL = window.URL.createObjectURL(blob);
          let today = new Date(Date.now());
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download =
            "reporte_candidatos_" +
            new Date().toISOString().substr(0, 10) +
            ".csv";
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(",");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    getCandidates() {
      this.loading.table = true;
      var data = new FormData();
      data.append("created_at", this.dates.join(","));
      data.append("idVacante", this.postId);
      data.append("id_usuario", this.id_usuario);
      data.append("director", "true");

      Api.Selection()
        .getCandidates(data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loading.table = false;
          this.listCandidate = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    dataUser() {
      if (this.$store.getters.getUser) {
        this.id_usuario = this.$store.getters.getUser.id;
      }
    },
    goBack(){
      this.$router.push({
        name: "vacanciesList"
      });
    }
  },
  mounted() {
    this.postId = this.$route.params.id;
    this.getCandidates();
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  created() {
    this.dataUser();
  },
  watch: {
    "$store.state.user": {
      handler(newValue, oldValue) {
        this.dataUser();
      },
    },
  },
};
</script>

<style lang="less">
@import "./../../assets/css/main.less";
.candidate-list__table {
  height: 780px;
  position: relative;
}

.vacancies__table {
  height: 600px;
  position: relative;
}

.candidate-list__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}
.candidate-list__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}
.candidate-list__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 37px;
  color: #353535;
}
.candidate-list__header {
  color: #353535 !important;
}

.candidate-list__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-list__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}
</style>
