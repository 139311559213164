<template>
  <div class="index-reference_card__container px-13">
    <div class="mb-xl-reference_card">
      <v-row class="mt-10">
        <v-col class="text-left pb-0 pl-0" cols="12">
          <h1 class="reference_card__title mb-6">Le tengo la ficha</h1>
        </v-col>
        <v-col class="pt-0 pl-0" cols="6">
          <p class="reference_card__subtitle_light text-left">
            <strong>Vacantes</strong>
          </p>
          <v-row class="reference_card__container px-4">
            <template v-for="data in listVacancies">
              <v-col
                v-if="data.post_title != 'Otros'"
                :key="data.id"
                cols="12"
                xs="12"
                md="12"
                lg="6"
                xl="6"
              >
                <v-card
                  style="height: 100%; border-radius: 15px"
                  class="border-control-card-1 control-life-projects__desktop"
                  outlined
                  elevation="0"
                >
                  <img
                    alt="Imagen de control de vida"
                    style="width: 100%"
                    :src="data.post_img"
                    height="150"
                  />

                  <v-card-text
                    style="height: 170px"
                    class="text-left pt-2 pb-0"
                  >
                    <p class="ml-3 reference_card__subtitle_1 text-center">
                      <strong> {{ data.post_title }} </strong>
                    </p>
                    <div class="text-3-lines" v-html="data.objetivo"></div>
                    <v-layout justify-center>
                      <v-btn
                        @click="openModal(data)"
                        class="mt-1"
                        color="#466be3"
                        rounded
                        dark
                        small
                        >Ver detalles</v-btn
                      >
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col class="pt-0" cols="6">
          <p class="reference_card__subtitle_light text-left">
            <strong>Mis referidos</strong>
          </p>
          <v-data-table
            :items-per-page="10"
            :headers="headers"
            :items="listReferences"
            :footer-props="{ itemsPerPageOptions: [5, 10] }"
            class="elevation-1 reference-card__table"
          >
            <template v-slot:no-results>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 document-index__alert-pending"
              >
                <b>No se encontraron resultados.</b>
              </v-alert>
            </template>
            <template class="pl-0 pr-0" v-slot:no-data>
              <v-alert
                text
                dense
                type="info"
                class="mt-3 document-index__alert-pending"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <template v-slot:item.documentName="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.documentName }}
                </p>
              </div>
            </template>
            <template v-slot:item.publicationDate="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.publicationDate }}
                </p>
              </div>
            </template>

            <template v-slot:item.status_reference_name="{ item }">
              <v-chip
                v-if="item.status_reference_name"
                :color="getColorStatus(item.status_reference_name)"
                dark
                small
              >
                <strong> {{ item.status_reference_name }} </strong>
              </v-chip>
            </template>

            <template v-slot:item.signatureDate="{ item }">
              <div>
                <p class="mb-0 mt-2 text-1-1-lines">
                  {{ item.signatureDate }}
                </p>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <template
                v-if="
                  item.documentName === 'Memorando de transitoriedad en casa'
                "
              >
                <p class="mb-0">Firmado desde la APP</p>
              </template>
              <template v-else>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    rounded
                    small
                    style="color: #fff"
                    :style="{
                      'background-color': hover ? '#324CA1' : '#466BE3',
                    }"
                    @click="open(item)"
                  >
                    Ver
                  </v-btn>
                </v-hover>
              </template>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip
                v-if="item.status_reference_name == 'Error'"
                max-width="300"
                color="#000"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small> mdi-text-box-multiple-outline </v-icon>
                  </v-btn>
                </template>
                <span>
                  <strong>OBSERVACIÓN: </strong> {{ item.observations }}
                </span>
              </v-tooltip>
              <v-tooltip max-width="300" color="#000" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goDeteils(item)"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="blue"
                    fab
                  >
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                </template>
                <span> Detalles del referido </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="openDetails" width="600">
      <v-card class="py-3 px-3">
        <v-card-title> <strong> Detalle de la vacante </strong></v-card-title>
        <v-card-text class="text-left">
          <label class="text-label-reference-card">Título </label>
          <p class="mb-0 camel-case">{{ dataInfo.post_title }}</p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <label class="text-label-reference-card"> Objetivos </label>
          <div v-html="dataInfo.objetivo"></div>
          <p class="mb-0 camel-case">{{ dataInfo.post_title }}</p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <label class="text-label-reference-card"> Experiencia </label>
          <p class="mb-0 camel-case">{{ dataInfo.experiencia }}</p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <label class="text-label-reference-card"> País</label>
          <p class="mb-0 camel-case">
            {{ dataInfo.pais }}
          </p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <label class="text-label-reference-card"> Ciudad </label>
          <p class="mb-0 camel-case">
            <template v-for="data in dataInfo.ciudad"> {{ data }} , </template>
          </p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <label class="text-label-reference-card"> Tipo de contrato </label>
          <p class="mb-0 camel-case">{{ dataInfo.tipo_contrato }}</p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <template v-if="dataInfo.numero_de_vacantes">
            <label class="text-label-reference-card">
              Número de vacantes
            </label>
            <p class="mb-0 camel-case">{{ dataInfo.numero_de_vacantes }}</p>
            <v-divider class="mt-1 pb-1"></v-divider>
          </template>

          <template v-if="dataInfo.rango_salarial">
            <label class="text-label-reference-card"> Rango salarial </label>
            <p class="mb-0 camel-case">{{ dataInfo.rango_salarial }}</p>
            <v-divider class="mt-1 pb-1"></v-divider>
          </template>

          <label class="text-label-reference-card"> Fecha de ingreso </label>
          <p class="mb-0 camel-case">{{ dataInfo.mes_ingreso }}</p>
          <v-divider class="mt-1 pb-1"></v-divider>

          <!-- <label class="text-label-reference-card">
            Fecha de publicación
          </label>
          <p class="mb-0 camel-case">{{ dataInfo.post_date }}</p>
          <v-divider class="mt-1 pb-1"></v-divider> -->
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466be3"
                outlined
                rounded
                @click="openDetails = false"
              >
                <strong> Cerrar </strong>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                @click="(openDetails = false), (openRegister = true)"
                block
                color="#466be3"
                dark
                rounded
              >
                <strong> Registrar ficha </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openRegister" width="600">
      <v-card class="py-3 px-3">
        <v-card-title>
          <strong> Registrar ficha (datos del referido) </strong></v-card-title
        >
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col class="py-0" cols="6">
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  label="Primer nombre *"
                  v-model="formInfo.firtsName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  label="Segundo nombre"
                  v-model="formInfo.secondName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  label="Primer apellido *"
                  v-model="formInfo.firtsLastName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  label="Segundo apellido"
                  v-model="formInfo.secondLastName"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  :items="listTypeDocument"
                  label="Tipo documento *"
                  v-model="formInfo.typeDocument"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  label="Número de documento *"
                  v-model="formInfo.numberDocument"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field label="Correo" v-model="formInfo.email">
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  label="Celular *"
                  v-model="formInfo.phone"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-switch v-model="formInfo.brm" label="Acepto T&C*"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466be3"
                outlined
                rounded
                @click="
                  () => {
                    openRegister = false;
                    formInfo.brm = false;
                  }
                "
              >
                <strong> Cerrar </strong>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="saveInfo()" block color="#466be3" dark rounded>
                <strong> Registrar ficha </strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs" max-width="600">
      <v-card>
        <v-card-title> Información sobre referidos </v-card-title>
        <v-card-text class="text-left">
          <ul>
            <li>
              Debes ser colaborador activo de BRM (tanto al momento de referir
              como al contratar al referido*).
            </li>
            <li>El referido puede ser familiar y conocido.</li>
            <li>
              El referido sólo puede participar en un proceso de selección.
            </li>
            <li>
              El referido que no pasa el proceso de selección para un cargo,
              sólo podrá presentarse a un nuevo proceso 3 meses después.
            </li>
            <li>
              El referido, en caso de haber sido empleado de BRM, sólo podrá
              participar en el proceso de selección de BRM 6 (seis) meses
              después de su retiro, y siempre que cumpla las condiciones de
              reintegro que son potestad del área de Atracción y Selección.
            </li>
            <li>
              Una vez contratado tu referido, BRM te pagará directamente a tu
              cuenta de nómina.
            </li>
            <li>
              Las vacantes activas las encuentras en Okan y es a través de esta
              plataforma, que registras tu ficha y haces el seguimiento de su
              proceso.
            </li>
            <li>
              No hay límite de personas para referir, ¡puedes ganar con todas
              las fichas que tengas!
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogs = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Procesando...
      </v-progress-circular>
    </v-overlay>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
  </div>
</template>
<script>
import Alert from "../../components/Alert.vue";
import Api from "@/utils/api";
import color from "@/mixins/color";

export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      openDetails: false,
      openRegister: false,
      menu2: false,
      formInfo: {
        firtsName: "",
        secondName: "",
        firtsLastName: "",
        secondLastName: "",
        typeDocument: "",
        numberDocument: "",
        email: "",
        phone: "",
        post_id: "",
        brm: false,
      },
      dialogs: false,
      headers: [
        {
          text: "Nombre del referido",
          value: "name_referred",
          align: "start",
          class: "document__header",
          sortable: false,
        },
        {
          text: "Fecha",
          value: "created_at",
          align: "center",
          class: "document__header",
        },
        {
          text: "Estado",
          value: "status_reference_name",
          align: "center",
          class: "document__header",
        },
        {
          text: "Observación",
          value: "actions",
          align: "center",
          class: "document__header",
        },
      ],
      loading: false,
      listReferences: [],
      listVacancies: [],
      listTypeDocument: [
        "Cédula de ciudadanía",
        "PEP",
        "Cédula de extranjería",
      ],
      loadingAll: false,
      info: {},
      infoToken: {
        document: "",
      },
      infoProfile: null,
      name: "",
      dataInfo: {
        post_title: "",
      },
      token: "",
    };
  },
  computed: {
    user() {
            return this.$store.getters.getUser;
        },
  },
  methods: {
    openModal(data) {
      this.openDetails = true;
      this.dataInfo = data;
      this.formInfo.post_id = data.post_id;
    },
    goDeteils(data) {
      localStorage.setItem("infoReference", JSON.stringify(data));
      this.$router.push({
        name: "referenceReportDetail",
        params: { id: data.reference_id },
      });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));

        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    saveInfo() {
      if (this.$refs.form.validate()) {
        this.loadingAll = true;
        this.formInfo.post_title = this.dataInfo.post_title;
        this.formInfo.pais_vacante = this.dataInfo.pais
        this.formInfo.id_usuario = this.dataInfo.id_usuario;
        Api.LaborRelations()
          .addReferenceOkan(this.token, this.formInfo)
          .then((res) => {
            console.log(res);
            this.getReference();
            (this.openRegister = false),
              (this.alert = {
                txt: res.data.message,
                title: "Excelente",
                type: "success",
                open: true,
                redirect: "",
              });
          })
          .catch((error) => {
            this.alert = {
              txt: error.response.data.message,
              title: "¡Oh no!",
              type: "error",
              open: true,
              redirect: "",
            };
            console.log(error);
          })
          .finally(() => {
            this.loadingAll = false;
          });
      }
    },
    getReference() {
      this.loadingAll = true;
      const currentUser = this.$store.getters.getUser;
      // const id_usuario = this.$store.getters.getUser.profile.id;
      var data = {
        statusId: null,
        personalReference: true,
        currentUser: currentUser,
      };
      Api.LaborRelations()
        .getReference(this.token, data)
        .then((res) => {
          console.log(res.data);
          this.listReferences = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },

    getVacancies() {
      this.loading = true;
      Api.Selection()
        .getVacanciesget(this.infoProfile.country)
        // .getVacanciesget()
        .then((resp) => resp.json())
        .then((data) => {
          this.loading = false;
          this.listVacancies = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].post_status == "publish") {
              this.listVacancies.push(data[i]);
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },

    getDataInfo() {
      this.loadingAll = true;
      this.data.userid = this.infoToken.document;
      this.data.useremail = this.infoToken.emailPersonal;

      //console.log(`datos prueba -- ${JSON.stringify(this.data)}`);
      Api.noAuth()
        .getDataInfo(this.data.userId)
        .then((resp) => resp.json())
        .then((data) => {})
        .catch((error) => {
          console.log(error);
        });

      // console.log(this.data);
      this.modal.projectM = false;
    },
  },
  mounted() {
    this.infoProfile = this.$store.getters.getUser;
    if (this.infoProfile) {
      this.getVacancies();
      this.getReference();
    }
  },
  created() {
    this.getToken();
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.infoProfile = newUser;
        this.getVacancies();
        this.getReference();
      }
    },
    "formInfo.brm"(newVal) {
      this.dialogs = newVal;
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.text-right {
  text-align: left;
}
.camel-case {
  text-transform: capitalize !important;
}

.reference-card__table {
  height: 600px;
  position: relative;
}

.reference-card__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.document__header {
  color: #353535 !important;
}

.text-label-reference-card {
  color: #141b33 !important;
  text-align: initial;
  font-weight: bold;
}
.reference_card__container {
  margin-left: -30px;
  height: 600px;
  overflow-y: scroll;
}

.reference_card__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.reference_card__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.reference_card__subtitle_1 {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #353535;
}

.reference_card__subtitle_light {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 35px;
  color: #353535;
}

.mb-sm-reference_card {
  display: none;
}

.dialogMessage {
  padding-top: 35px !important;
  font-size: 30px;
  color: #466be3;
  font-family: "AsapSemiBold";
  font-weight: 700;
  text-align: center;
  word-break: break-word !important;
  line-height: 30px;
}

.request-project {
  color: #466be3;
  font-family: "AsapSemiBold" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
}

.request-txt-project {
  font-family: "ProximaNovaLight";
  color: #898989 !important;
  font-weight: 300;
}

.index-reference_card__container {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.index-reference_card__alert-desktop {
  display: block !important;
}

.index-reference_card__alert-mobile {
  display: none !important;
}

/* LAPTOP 4k */

@media (max-width: 2000px) {
  .dialog {
    width: 30% !important;
  }

  .reference_card-spacing {
    padding-left: 32px !important;
    padding-right: 32px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-sm-reference_card {
    display: block;
    text-align: initial;
  }

  .mb-xl-reference_card {
    display: none;
  }

  .reference_card-spacing {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .index-reference_card__container {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 70px !important;
  }

  .index-reference_card__alert-desktop {
    display: none !important;
  }

  .index-reference_card__alert-mobile {
    display: block !important;
  }

  .index-reference_card__title-mobile {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-reference_card {
    display: none;
  }

  .text-reference_card-dark-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }

  .c-reference_card {
    /* padding-top: 10px !important; */
    margin-top: 10px !important;
  }

  .global-reference_card {
    margin-top: 0 !important;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-reference_card {
    display: none;
  }
  .mb-sm-reference_card {
    display: block;
    text-align: initial;
  }
  .text-reference_card-dark-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-reference_card {
    display: none;
  }
  .mb-sm-reference_card {
    display: block;
    text-align: initial;
  }
  .text-reference_card-dark-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
}
</style>
