<template>
  <div id="app">
    <notifications style="top: 10%; right: -4px" :duration="5000" group="foo" />
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>
<script>
import Pusher from "pusher-js";
import Push from "push.js";
import Icon from "./assets/icon/logo_okan.png";
import Snowfall from "@/components/Snowfall.vue"; // Asegúrate de ajustar la ruta de importación

export default {
  data() {
    return {
      newFlag: null,
      mobileFlag: false,
    };
  },
  components: {
    Snowfall, // Registra el componente aquí
  },
  computed: {
    user() {
      let user = this.$store.getters.getUser;
      let start = this.$store.getters.getStart;
      if (user && start) {
        this.getNotifications(user);
        this.$store.dispatch("updateStart");
      }
    },
  },
  methods: {
    async getNotifications(info) {
      /**
       * You can use this to validate the pusher conect. DONT USER IN PROD
       * Pusher.logToConsole = true;
       */
      let key = process.env.VUE_APP_KEY;
      let cluster = process.env.VUE_APP_CLUSTER;
      const pusher = new Pusher(key, {
        cluster,
        forceTLS: true,
      });
      let costCenter = info.cc.replace(/ /g, "");
      let contractor = info.contractor.replace(/ /g, "");
      let headquarter = info.headquarters.replace(/ /g, "");
      let channels = {
        individual: `individual_${info.document_number}`,
        campaign: `campaign_id_${info.campaign_id}`,
        client: `client_id_${info.client_id}`,
        profile: `profile_id_${info.profile.id}`,
        costCenter: `cc_${costCenter}`,
        subCostCenter: `cod_scc_${info.cod_scc}`,
        contractor: `contractor_${contractor}`,
        headquarter: `headquarters_${headquarter}`,
        everybody: `everybody`,
      };
      for (let channel in channels) {
        if (
          !channels[channel].includes("null") &&
          !channels[channel].includes("undefined")
        ) {
          let subcribe = pusher.subscribe(channels[channel]);
          var app = this;
          subcribe.bind("my-event", function (data) {
            app.newFlag = true;
            let string = info.name;
            let firstLetter = string.slice(0, 1);
            let word = string.slice(1);
            let name = firstLetter.toUpperCase() + word;
            if (app.mobileFlag) {
              Push.create(name, {
                body: data.message.message,
                icon: Icon,
                vibrate: [200, 100],
              });
            } else {
              Push.create(name, {
                body: data.message.message,
                icon: Icon,
                timeout: 4000,
                onClick: function () {
                  window.focus();
                  this.close();
                },
              });
            }
            if (app.newFlag) {
              app.$store.dispatch("updateNofi");
            }
          });
        }
      }
    },
  },
  watch: {
    user: function (newCount, oldCount) {
      console.log("");
    },
  },
  mounted() {
    if (screen.width < 1024) {
      this.mobileFlag = true;
    }
  },
};
</script>

<style>
body {
  background-color: #f6f9ff !important;
}

#app {
  text-align: center !important;
  font-family: RobotoRegular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #353535;
  background-color: #f6f9ff !important;
  padding-left: 13%;
  padding-right: 11%;
}

.alert-c {
  top: 5%;
  right: -4px;
}

.hooper-track {
  padding: 0px !important;
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 20px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b3b3b3 transparent;
}

/* LAPTOP 4k */

@media (max-width: 1920px) {
  #app {
    padding-left: 7%;
    padding-right: 5%;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
  #app {
    padding-left: 2.7%;
    padding-right: 0.5%;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
  #app {
    padding-left: 3%;
    padding-right: 1%;
  }
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
  #app {
    padding-left: 2.5%;
    padding-right: 0%;
  }
}

/* TABLET */

@media (max-width: 1023px) {
  #app {
    padding-left: 0%;
    padding-right: 0%;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {}

/* MOBILE M */
@media (max-width: 375px) {}

/* MOBILE S */
@media (max-width: 320px) {}

/* @media screen and (min-width: 1280px) {
  #app {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

@media screen and (min-width: 1920px) {
  #app {
    width: 1540px !important;
    margin: 0 auto !important;
  }
}
@media screen and (min-width: 3840px) {
  #app {
    width: 1540px !important;
    margin: 0 auto !important;
  }
} */
</style>
