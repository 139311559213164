<template>
  <div class="pl-4 pr-4">
    <!-- Desktop -->
    <v-row class="mb-xl-commisions ml-3 mr-3" v-if="!isMobile">
      <v-col class="mt-14 pl-0 pr-0 pb-8 text-left" cols="12">
        <h1 class="index-commission-claims__title">
          Reclamación pago de comisiones
        </h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-card
              elevation="0"
              outlined
              rounded
              class="pa-3"
              style="border-radius: 15px !important; height: 300px"
            >
              <v-title>
                <p class="txt-title-com">
                  <strong>
                    Elija el rango de fechas que desea consultar
                  </strong>
                </p>
              </v-title>
              <v-card-actions>
                <v-row>
                  <v-col cols="12">
                    <v-menu
                      ref="menu1"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="00/00/0000"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker range v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cerrar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(date)"
                        >
                          Seleccionar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        @click="getCommissions()"
                        block
                        rounded
                        style="color: #fff"
                        :style="{
                          'background-color': hover ? '#324CA1' : '#466BE3',
                        }"
                      >
                        BUSCAR
                      </v-btn>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              elevation="0"
              outlined
              rounded
              class="pa-3"
              style="border-radius: 15px !important; height: 300px"
            >
              <v-title>
                <p class="txt-title-com">
                  <strong> Reportes de errores </strong>
                </p>
              </v-title>
              <v-card-actions>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :loading="loading.table"
                      :items-per-page="2"
                      :headers="headers"
                      :items="listHistoryReport"
                      :footer-props="{ itemsPerPageOptions: [1, 2] }"
                      class="elevation-0 commissions__table"
                    >
                      <template v-slot:no-results>
                        <v-alert
                          text
                          dense
                          type="info"
                          class="mt-3 index-commissions__alert-pending"
                        >
                          <b>No se encontraron resultados.</b>
                        </v-alert>
                      </template>
                      <template class="pl-0 pr-0" v-slot:no-data>
                        <v-alert
                          text
                          dense
                          type="info"
                          class="mt-3 index-commissions__alert-pending"
                        >
                          <b>No se encontró información.</b>
                        </v-alert>
                      </template>
                      <template v-slot:progress>
                        <v-progress-linear
                          color="#214484"
                          :height="5"
                          indeterminate
                          stream
                        ></v-progress-linear>
                      </template>
                      <template v-slot:item.fecha_ingreso="{ item }">
                        <div>
                          <p class="mb-0 mt-2 text-1-1-lines">
                            {{ item.fecha_ingreso }}
                          </p>
                        </div>
                      </template>
                      <template v-slot:item.estado="{ item }">
                        <div>
                          <v-chip
                            :color="getColorStatus(item.estado)"
                            dark
                            small
                            style="width: 90px"
                            class="index-commissions__v-chip"
                          >
                            {{ item.estado }}
                          </v-chip>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-card
              elevation="0"
              outlined
              rounded
              class="pa-3"
              style="border-radius: 15px !important; height: 300px"
            >
              <template v-if="resultDate">
                <div class="d-flex">
                  <p class="txt-title-com" style="width: 60%">
                    <strong> Resultado de búsqueda </strong>
                  </p>
                  <v-btn
                    @click="modal.add = true"
                    rounded
                    outlined
                    style="color: #466be3; width: 40%"
                  >
                    <strong>Reportar un error</strong>
                  </v-btn>
                </div>
                <div
                  v-if="listCommisions.length == 0"
                  style="height: 80%"
                  class="d-flex align-center"
                >
                  <v-alert dense text prominent type="info" class="text-left">
                    * No se encontraron resultados en la fecha seleccionada
                    <br />
                    * Si no encuentra sus comisiones, no se preocupe, diríjase
                    <v-btn text @click="go()" color="#476be4" class="pl-0 pr-0">
                      <u><strong>aquí</strong></u>
                    </v-btn>
                    y descargue su desprendible de nómina.
                  </v-alert>
                </div>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left table-header"
                          style="text-align: center !important"
                        >
                          Monto
                        </th>
                        <th
                          class="text-left table-header"
                          style="text-align: center !important"
                        >
                          Tipo de comisión
                        </th>
                        <th
                          class="text-left table-header"
                          style="text-align: center !important"
                        >
                          Fecha
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in listCommisions" :key="item.name">
                        <td
                          class="text-left table-body"
                          style="text-align: center !important"
                        >
                          {{ validateCOP(item.amount) }}
                        </td>
                        <td
                          class="text-left table-body"
                          style="text-align: center !important"
                        >
                          {{ item.type }}
                        </td>
                        <td
                          class="text-left table-body"
                          style="text-align: center !important"
                        >
                          {{ item.date }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <template v-else>
                <div class="d-flex">
                  <p class="txt-title-com" style="width: 60%">
                    <strong>
                      Agregue un rango de fecha para ver resultados
                    </strong>
                  </p>
                  <v-btn
                    @click="modal.add = true"
                    rounded
                    outlined
                    style="color: #466be3; width: 40%"
                  >
                    <strong>Reportar un error</strong>
                  </v-btn>
                </div>
                <div style="height: 80%" class="d-flex align-center">
                  <v-alert
                    text
                    prominent
                    type="info"
                    class="text-left"
                    style="width: 100%"
                  >
                    Aquí visualizará su respuesta
                  </v-alert>
                </div>
              </template>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              elevation="0"
              outlined
              rounded
              class="pa-3"
              style="border-radius: 15px !important; height: 300px"
            >
              <v-title>
                <p class="txt-title-com">
                  <strong> Estado de su reporte </strong>
                </p>
              </v-title>
              <v-card-actions style="display: block">
                <v-stepper v-model="step" alt-labels class="mt-4" elevation="0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-stepper-header v-bind="attrs" v-on="on">
                        <v-stepper-step
                          :complete="step >= 1"
                          color="#476be4"
                          step="1"
                        >
                          Abierto
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                          :complete="step >= 2"
                          color="#476be4"
                          step="2"
                        >
                          En curso
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                          :complete="step >= 3"
                          color="#476be4"
                          step="3"
                        >
                          Finalizado
                        </v-stepper-step>
                      </v-stepper-header>
                    </template>
                    <span> {{ stepComentary }} </span>
                  </v-tooltip>
                </v-stepper>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- MOBILE -->
    <div class="mb-sm-commisions" v-else>
      <v-row class="px-0 mx-0 mt-4">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            Todas sus encuestas
          </p>
        </v-col>
        <v-col cols="12">
          <p class="txt-title-com">Comisiones</p>
          <p class="txt-subtitle-com mb-0">Fecha de consulta</p>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="00/00/0000"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker range v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cerrar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(date)">
                    Seleccionar
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-btn
                @click="getCommissions()"
                class="btn-main"
                block
                dark
                rounded
              >
                BUSCAR
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          :class="
            resultDate === false ? 'result-hidden-mobile' : 'result-show-mobile'
          "
        >
          <p class="txt-title-com mt-0 mb-5">Resultado de búsqueda</p>
          <v-alert v-if="listCommisions.length == 0" dense text type="info">
            * No se encontraron resultados en la fecha seleccionada <br />
            * Si no encuentra sus comisiones, no se preocupe diríjase
            <v-btn text @click="go()" color="#476be4" class="pl-0 pr-0">
              <u><strong>aquí</strong></u>
            </v-btn>
            y descargue su desprendible de nómina.
          </v-alert>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left table-header"
                    style="text-align: center !important"
                  >
                    Monto
                  </th>
                  <th
                    class="text-left table-header"
                    style="text-align: center !important"
                  >
                    Tipo de comisión
                  </th>
                  <th
                    class="text-left table-header"
                    style="text-align: center !important"
                  >
                    Fecha
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listCommisions" :key="item.name">
                  <td
                    class="text-left table-body"
                    style="text-align: center !important"
                  >
                    {{ validateCOP(item.amount) }}
                  </td>
                  <td
                    class="text-left table-body"
                    style="text-align: center !important"
                  >
                    {{ item.type }}
                  </td>
                  <td
                    class="text-left table-body"
                    style="text-align: center !important"
                  >
                    {{ item.date }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12">
          <p class="txt-title-com">¿Hay algún error?</p>
          <v-btn
            @click="modal.add = true"
            class="mt-3"
            outlined
            color="#3957B9"
            rounded
          >
            <strong> REPORTAR UN ERROR</strong>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <p class="txt-title-com pb-0 mb-0">
            Estado de su reporte
            <v-btn
              x-small
              @click="organizeHistory()"
              outlined
              rounded
              color="#3957B9"
            >
              Historial
            </v-btn>
          </p>

          <v-stepper v-model="step" vertical alt-labels class="mt-1 mb-15">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-stepper-header v-bind="attrs" v-on="on">
                  <v-stepper-step
                    :complete="step >= 1"
                    color="#476be4"
                    step="1"
                  >
                    Abierto
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="step >= 2"
                    color="#476be4"
                    step="2"
                  >
                    En curso
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="step >= 3"
                    color="#476be4"
                    step="3"
                  >
                    Finalizado
                  </v-stepper-step>
                </v-stepper-header>
              </template>
              <span> {{ stepComentary }} </span>
            </v-tooltip>
          </v-stepper>
        </v-col>
      </v-row>
    </div>

    <!-- Dialogo de creación -->

    <v-dialog v-model="modal.add" max-width="600">
      <v-card>
        <v-card-title>
          <p class="txt-card-text-error pl-6 pr-6">
            INGRESE LOS CAMPOS PARA REPORTAR EL ERROR
          </p>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  disabled
                  v-model="form.nombreCompleto"
                  label="Nombre completo"
                ></v-text-field>
                <v-text-field
                  disabled
                  v-model="form.documento"
                  label="Documento"
                ></v-text-field>
                <v-text-field
                  disabled
                  v-model="form.correo"
                  label="Correo"
                ></v-text-field>
                <v-text-field
                  disabled
                  v-model="form.celular"
                  label="Celular"
                ></v-text-field>
                <v-divider></v-divider>
                <v-select
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.mesesReclamacion"
                  item-text="name"
                  item-value="id"
                  :items="listMonth"
                  label="Seleccione un mes"
                ></v-select>
                <v-textarea
                  :rules="[
                    (v) => !!v || 'Este campo es requerido',
                    (v) => v.length <= 500 || 'Supera el límite de caracteres ',
                  ]"
                  v-model="form.reclamacion"
                  name="input-7-1"
                  label="Descripción"
                  counter=""
                  value=""
                ></v-textarea>
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.contactPhone"
                  label="Número de contacto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-hover v-slot="{ hover }">
            <v-btn
              :loading="loadingAll"
              @click="createReport()"
              block
              rounded
              style="color: #fff"
              :style="{
                'background-color': hover ? '#324CA1' : '#466BE3',
              }"
            >
              ENVIAR REPORTE
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo historial -->

    <v-dialog v-model="modal.history" max-width="600">
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <p class="txt-card-text-history">HISTORIAL DE REPORTES</p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <template v-if="listHistoryReport.length === 0">
            <v-card
              elevation="0"
              :class="
                historyDialog === false
                  ? 'result-hidden-mobile'
                  : 'result-show-mobile'
              "
            >
              <v-text class="text-title">
                No se han registrado reportes hasta el momento
              </v-text>
            </v-card>
          </template>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left table-header">Fecha de registro</th>
                  <th class="text-left table-header">Estado</th>
                  <th class="text-left table-header">Respuesta</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listHistoryReport" :key="item.name">
                  <td class="text-left table-body">{{ item.fecha_ingreso }}</td>
                  <td class="text-left table-body">{{ item.estado }}</td>
                  <td class="text-left table-body">{{ item.respuesta }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-if="loading">
          <v-layout justify-center>
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo Filtro Fecha -->

    <v-dialog v-model="dialogFilterDate" max-width="600">
      <v-card class="pb-5">
        <v-card-title>
          <v-layout justify-center>
            <p class="txt-card-text-history text-date">
              Seleccione rango de fechas que desea ver
            </p>
          </v-layout>
        </v-card-title>
        <v-text class="text-title"> Debe agregar un rango de fechas </v-text>
      </v-card>
    </v-dialog>

    <!-- Mensaje -->

    <v-dialog v-model="modal.sms" max-width="430">
      <v-card>
        <v-card-title class="txt-card-text-error">
          <v-row>
            <v-col cols="11">
              {{ messageAlert.title }}
            </v-col>
            <v-col class="pl-0 pr-5" cols="1">
              <v-btn small icon fab @click="modal.sms = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          {{ messageAlert.sms }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }} 
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import moment from "moment";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      isMobile: false,
      alert: {
        txt: "",
        title: "",
        type: "",
        redirect: "",
        open: false,
      },
      step: 0,
      stepComentary: "",
      loadingAll: false,
      loading: false,
      name: "",
      form: {
        nombreCompleto: "",
        correo: "",
        documento: "",
        celular: "",
        company: "",
        mesesReclamacion: null,
        contactPhone: "",
        reclamacion: "",
      },
      messageAlert: {
        title: "",
        sms: "",
      },
      listCompany: [
        {
          id: 1,
          name: "BRM",
        },
        {
          id: 2,
          name: "PREFERENTE",
        },
        {
          id: 3,
          name: "GIVING",
        },
        {
          id: 4,
          name: "DEEPLOY",
        },
        {
          id: 5,
          name: "SLH",
        },
        {
          id: 6,
          name: "ACTIVOS",
        },
      ],
      listMonth: [
        {
          id: "1",
          name: "Enero",
        },
        {
          id: "2",
          name: "Febrero",
        },
        {
          id: "3",
          name: "Marzo",
        },
        {
          id: "4",
          name: "Abril",
        },
        {
          id: "5",
          name: "Mayo",
        },
        {
          id: "6",
          name: "Junio",
        },
        {
          id: "7",
          name: "Julio",
        },
        {
          id: "8",
          name: "Agosto",
        },
        {
          id: "9",
          name: "Septiembre",
        },
        {
          id: "10",
          name: "Octubre",
        },
        {
          id: "11",
          name: "Noviembre",
        },
        {
          id: "12",
          name: "Diciembre",
        },
      ],
      modal: {
        add: false,
        sms: false,
        history: false,
      },
      menu: false,
      menu2: false,
      date: null,
      infoToken: {
        document: "",
      },
      listCommisions: [],
      info: {},
      token: "",
      listHistoryReport: [],
      lastReport: null,
      resultDate: false,
      dialogFilterDate: false,
      historyDialog: false,
      headers: [
        {
          text: "Fecha reportada",
          value: "fecha_ingreso",
          class: "index-commissions__header",
          align: "center",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado",
          class: "index-commissions__header",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getCurrent() {
      if (this.$store.getters.getUser) {
        console.log(this.currentUser);
      }
    },
    go() {
      let newURL = document.createElement("a");
      newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
      newURL.href = "https://colaborador.brm.com.co/WEBKACTUS/";
      newURL.target = "_blank";
      document.body.appendChild(newURL);
      newURL.click();
    },
    organizeHistory() {
      this.modal.history = true;
      for (let i = 0; i < this.listHistoryReport.length; i++) {
        this.listHistoryReport[i].dateFormat = new Date(
          this.listHistoryReport[i].fecha_ingreso
        );
      }
      this.listHistoryReport.sort((a, b) => b.dateFormat - a.dateFormat);
    },
    validateCOP(value) {
      return new Intl.NumberFormat("es-CO", {
        currency: "COP",
        style: "currency",
      }).format(value);
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    searchCompany(com) {
      var company = null;
      for (let i = 0; i < this.listCompany.length; i++) {
        if (com == this.listCompany[i].name) {
          company = this.listCompany[i].id;
        }
      }
      return company;
    },
    searchCompanyName(com) {
      var company = null;
      for (let i = 0; i < this.listCompany.length; i++) {
        if (com == this.listCompany[i].id) {
          company = this.listCompany[i].name;
        }
      }
      return company;
    },
    getProfile() {
      Api.noAuth()
        .profile(this.infoToken.document)
        .then((res) => {
          if (res.data.cod == 0) {
            this.info = res.data.data;
            if (this.info) {
              this.form.nombreCompleto = this.info.full_name;
              this.form.correo = this.info.personal_email;
              this.form.documento = this.info.document_number;
              this.form.celular = this.info.phone;
              this.form.company = this.searchCompany(this.info.contractor);
              this.form.contactPhone = this.info.phone;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createReport() {
      if (this.$refs.form.validate()) {
        this.currentUser = this.$store.getters.getUser;
        this.loadingAll = true;
        this.form.company = "BRM";
        this.form.campaniaId = this.currentUser.campaign_id;
        this.form.campaniaNombre = this.currentUser.campaign;
        this.form.centroCosto = this.currentUser.cc;
        this.form.codCentroCostos = this.currentUser.cod_scc;
        this.form.correoMrChispa = this.currentUser.email;
        this.form.numeroIngreso = this.form.celular;
        this.form.pais = this.currentUser.country,
        this.form.ciudad = this.currentUser.headquarters_city,
        this.modal.add = false;
        Api.noAuth()
          .createReport(this.form, this.token)
          .then((res) => {
            this.alert.title = "Solicitud Creada de Manera Exitosa";
            this.alert.txt = res.data.message;
            this.alert.type = "success";
          })
          .catch((error) => {
            this.alert.title = "Solicitud Creada De Manera Exitosa";
            this.alert.txt = res.data.message;
            this.alert.type = "success";
            // this.alert.title = "¡Oh no!";
            // this.alert.txt = error.response.data.message;
            // this.alert.type = "error";
            // this.modal.add = true;
          })
          .finally(() => {
            this.loadingAll = false;
            this.alert.open = true;
          });
      }
    },
    getReport() {
      this.loading = true;
      Api.noAuth()
        .getReportPayroll(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            let history = res.data.data;
            history.forEach((element) => {
              let date = moment(element.fecha_ingreso).format("L");
              element.fecha_ingreso = date;
              let status;
              switch (element.estado) {
                case "En Curso":
                  status = "En proceso";
                  return (element.estado = status);
                case "Cerrado":
                  status = "Completado";
                  return (element.estado = status);
                case "Abierto":
                  status = "Pendiente";
                  return (element.estado = status);
                default:
                  status = "Error";
                  return (element.estado = status);
              }
            });
            this.listHistoryReport = history;
            if (res.data.data.length === 0) {
              this.historyDialog = true;
            }
            if (res.data.data.length > 0) {
              var lastNum = res.data.data.length - 1;
              this.lastReport = this.listHistoryReport[lastNum];
              var status = this.lastReport.estado;
              var comentary = this.lastReport.respuesta;

              switch (status) {
                case "Abierto":
                  this.stepComentary = comentary;
                  this.step = 1;
                  break;
                case "En Curso":
                  this.stepComentary = comentary;
                  this.step = 2;
                  break;
                case "Finalizado":
                  this.stepComentary = comentary;
                  this.step = 3;
                  break;
                default:
                  this.stepComentary = comentary;
                  this.step = 1;
                  break;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCommissions() {
      this.loadingAll = true;
      if (!this.date || !this.date[0] || !this.date[1]) {
        this.loadingAll = false;
        this.alert.open = true;
        this.alert.title = "¡Oh no!";
        this.alert.txt = "Debe agregar un rango de fecha";
        this.alert.type = "error";
      } else {
        var data = {
          dateEnd: this.date[1],
          dateIni: this.date[0],
        };
        Api.noAuth()
          .getCommissions(data, this.token)
          .then((res) => {
            if (res.data.cod == 0) {
              this.resultDate = true;
              this.listCommisions = res.data.data;

              for (let i = 0; i < this.listCommisions.length; i++) {
                this.listCommisions[i].date = new Date(
                  this.listCommisions[i].created_at
                )
                  .toISOString()
                  .substr(0, 10);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingAll = false;
          });
      }
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
  },
  created() {
    this.getToken();
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
    this.getCurrent();
  },
  mounted() {
    var array = [
      {
        fechas: new Date("2017-10-27"),
        registros: 9,
      },
      {
        fechas: new Date("2019-10-26"),
        registros: 8,
      },
      {
        fechas: new Date("2018-10-25"),
        registros: 5,
      },
      {
        fechas: new Date("2017-1-15"),
        registros: 5,
      },
      { fechas: new Date("2019-10-29"), registros: 52 },
    ];
    this.getProfile();
    this.getReport();
    this.getCurrent();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
  background-color: #f0f8ff00;
}

.txt-card-text-error {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #466be3;
  word-break: break-word;
}

.txt-card-text-history {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 57px;
  text-align: center;
  color: #466be3;
}

.mb-sm-commisions {
  display: none;
}

.txt-title-com {
  font-family: "RobotoRegular";
  font-size: 18px;
  line-height: 37px;
  color: #353535;
  text-align: left;
}

.txt-subtitle-com {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #353535;
  text-align: left;
}

.table-header {
  background-color: #f2f4ff !important;
  color: #353535 !important;
  font-size: 16px !important;
  border: 1px solid #fff !important;
}

.table-body {
  color: #626262 !important;
}

.result-hidden {
  visibility: hidden;
}

.result-show {
  visibility: visible;
}

.result-hidden-mobile {
  display: none;
}

.result-show-mobile {
  display: block;
}

.index-commission-claims__title {
  font-family: "RobotoRegular";
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.commissions__table {
  height: 210px;
  position: relative;
}

.commissions__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.index-commissions__header {
  color: #353535 !important;
}

tr,
th,
td,
span .commissions__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
}

.commissions__table .v-data-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.index-commissions__alert-pending {
  width: 100%;
}

.index-commissions__v-chip span {
  display: block !important;
  width: 100%;
  text-align: center;
}

/* LAPTOP 4k */
@media (max-width: 1900px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
}

/* TABLET */
@media (max-width: 1023px) {
  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .txt-title-com {
    font-size: 18px;
  }
  .txt-subtitle-com {
    font-size: 18px;
  }
  .txt-card-text-error {
    font-size: 25px;
  }
  .txt-card-text-history {
    font-size: 25px;
  }
  .mb-xl-commisions {
    display: none !important;
  }
  .mb-sm-commisions {
    display: block;
    text-align: initial;
  }
  .v-stepper__header {
    box-shadow: none !important;
  }
}

/* MOBILE L - 767px*/
@media (max-width: 600px) {
  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .txt-title-com {
    font-size: 18px;
  }

  .txt-subtitle-com {
    font-size: 18px;
  }

  .txt-card-text-error {
    font-size: 23px;
  }

  .txt-card-text-history {
    font-size: 23px;
  }

  .mb-xl-commisions {
    display: none !important;
  }

  .mb-sm-commisions {
    display: block;
    text-align: initial;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: auto !important;
  }
  .v-stepper__header {
    box-shadow: none !important;
  }

  .text-date {
    line-height: 25px;
  }
}
/* MOBILE M */
@media (max-width: 375px) {
  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .mb-sm-commisions {
    display: block;
    text-align: initial;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: auto !important;
  }
  .v-stepper__header {
    box-shadow: none !important;
  }
  .txt-card-text-error {
    font-size: 20px;
  }
  .txt-card-text-history {
    font-size: 20px;
  }

  .text-date {
    word-break: break-word !important;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }
  .mb-sm-commisions {
    display: block;
    text-align: initial;
  }

  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: auto !important;
  }
  .v-stepper__header {
    box-shadow: none !important;
  }
  .txt-card-text-error {
    font-size: 20px;
  }
  .txt-card-text-history {
    font-size: 18px;
  }
}
</style>
