export default {
    welcome: "Welcome",
    nonexistentKey: "Default text",
    routes: {
        BRMNews: "News",
        details: "Details",
        Podcast:"Podcast",  
        apps: "Applications",
        profile: "My business profile",
        carnet:"ID card",
        carneSecurity:"Security ID card",
        Requests: "Requests",
        BRMPrograms: "Programs",
        IHaveTheSheet: "I have the sheet",
        LifeControl: "Life Control",
        LaborRelations: "Labor Relations",
        Documents: "Documents",
        Referrals: "Referrals",
        ReferralDetail: "Referral Detail",
        Linguo:"Linguo",
        LinguoDev: "Linguo dev",
        LinguoBrands: "Linguo brands",
        InformationSecurity: "Information Security",
        AbsenceList: "Absence List",
        DeliveryReport: "Delivery Report",
        MinutesReport: "Minutes Report",
        DeletedMinutesReport: "Deleted Minutes Report",
        BRMQuestion: "Question",
        Form: "Form",
        Answer: "Respuesta",
        RegulationsAndPolicies: "Regulations and Policies",
        Detail: "Detail",
        CorporateDocuments: "Corporate Documents",
        Signature: "Signature",
        PayrollReport: "Payroll Report",
        NonSalariedBonus: "Non-Salaried Bonus",
        NonSalariedBonusReport: "Non-Salaried Bonus Report",
        DeletedBonusReport: "Deleted Bonus Report",
        DocumentsReport: "Documents Report",
        DeletedDocumentsReport: "Deleted Documents Report",
        LoginReport: "Reporte Login",
        ShiftGrid: "Shift Grid",
        Claims: "Claims",
        AttachFile: "Attach File",
        ReportByUsers: "Report by Users",
        ReportByPercentages: "Report by Percentages",
        Provisions: "Provisions",
        AttachProvisions: "Attach Provisions",
        Notifications: "Notifications",
        AttractionAndSelection: "Attraction and Selection",
        CreateVacancies: "Create Vacancies",
        ListCandidates: "List Candidates",
        CandidateDetails: "Candidate Details",
        ListVacancies: "List Vacancies",
        ListCandidates: "List Candidates",
        NewDevelopment: "Development requests",
        OwnRequests: "Own Requests",
        CreateNewRequest: "Create New Request",
        CreatedRequests: "Created Requests",
        OngoingRequests: "Ongoing Requests",
        DraftRequest: "Draft Request",
        EditDraftRequest: "Edit Draft Request",
        CopyRequests: "Copy Requests",
        RequestDetails: "Request Details",
        PMRequests: "PM Requests",
        AssignedRequests: "Assigned Requests",
        DisciplinaryProcesses: "Disciplinary Processes",
        CreateDisciplinaryProcess: "Create Disciplinary Process",
        DisciplinaryProcessDetails: "Disciplinary Process Details",
        RecognitionsForYou: "Recognitions For You",
        SuperStars: "Super Stars",
        DeliveryStars: "Delivery Stars",
        AceOfSpades: "Ace Of Spades",
        AllWinners: "All Winners",
        UpdatingWinners: "Updating Winners",
        DeletingWinners: "Deleting Winners",
        Agreements: "Agreements",
    },
    titleApp:{
        login: "Login",
        unauthorizedOkan: "Unauthorized Okan",
        Home:"Home",
        Podcast: "Podcast",
        BRMNews: "News",
        Apps: "Apps",
        MyProfile: "My Profile",
        Overtime: "Overtime",
        VoluntaryRetirement: "Voluntary Retirement",
        Requests: "Requests",
        VoluntaryResignationReport: "Voluntary Resignation Report",
        BRMPrograms: "Programs",
        IHaveTheFile: "I Have The File",
        LifeControl: "Life Control",
        LaborRelations: "Labor Relations",
        LaborRelationsDocuments: "Labor Relations Documents",
        ReferralReport: "Referral Report",
        Linguo: "Linguo",
        LinguoForDevelopers: "Linguo for Developers",
        LinguoForBrands: "Linguo for Brands",
        SecurityModule: "Security Module",
        CorporateDocumentsForManagers: "Corporate Documents for Managers",
        ActReports: "Act Reports",
        DeletedActReports: "Deleted Act Reports",
        BRMQuestion: "Question",
        FormDetails: "Form Details",
        FormResponses: "Form Responses",
        SafeConduct: "Safe Conduct",
        RegulationsAndPolicies: "Regulations and Policies",
        TermsAndConditions: "Terms and Conditions",
        CorporateDocuments: "Corporate Documents",
        CorporateDocumentsDetails: "Corporate Documents Details",
        CorporateDocumentsForManagers: "Corporate Documents for Managers",
        NonSalaryBonusDocumentReport: "Non-Salary Bonus Document Report",
        DeletedNonSalaryBonusDocumentsReport: "Deleted Non-Salary Bonus Documents Report",
        CorporateDocuments: "Corporate Documents",
        DocumentsReport: "Documents Report",
        DeletedDocumentsReport: "Deleted Documents Report",
        OKANIncomeReport: "OKAN Income Report",
        ConsolidatedReport: "Consolidated Report",
        Occupation: "Occupation",
        ShiftSchedule: "Shift Schedule",
        Commissions: "Commissions",
        CommissionMargins: "Commission Margins",
        AttachCommissionMargins: "Attach Commission Margins",
        SignaturesReport: "Signatures Report",
        SignaturesReportByPercentage: "Signatures Report by Percentage",
        CommissionMinutes: "Commission Minutes",
        CommissionProvision: "Commission Provision",
        UpdateCommissionProvision: "Update Commission Provision",
        Notifications: "Notifications",
        RecruitmentAndSelection: "Recruitment and Selection",
        CreateVacancies: "Create Vacancies",
        ListCandidates: "List Candidates",
        CandidateDetails: "Candidate Details",
        ListVacancies: "List Vacancies",
        NewDevelopment: "Development requests",
        OwnRequests: "Own Requests",
        CreateNewRequest: "Create New Request",
        CreatedRequests: "Created Requests",
        RequestsInProgress: "Requests in Progress",
        DraftRequest: "Draft Request",
        EditDraftRequest: "Edit Draft Request",
        CopiedRequests: "Copied Requests",
        RequestDetails: "Request Details",
        AssignedRequests: "Assigned Requests",
        DisciplinaryProcesses: "Disciplinary Processes",
        CreateNewDisciplinaryProcess: "Create New Disciplinary Process",
        DisciplinaryProcessList: "Disciplinary Process List",
        DisciplinaryProcessDetails: "Disciplinary Process Details",
        IDCard: "ID Card",
        SecurityIDCard: "Security ID Card",
        RecognitionForYou: "Recognition For You",
        SuperStars: "Super Stars",
        DeliveryStars: "Delivery Stars",
        AceOfSpades: "Ace of Spades",
        AllWinners: "All Winners",
        UpdatingWinners: "Updating Winners",
        DeletingWinners: "Deleting Winners",
        Agreements: "Agreements",
        AgreementDetails: "Agreement Details"
    },
    sidebar:{
        Home: "Home",
        BRMNews: "News",
        Podcast: "Podcast",
        Apps: "Apps",
        CardCode: "ID card Code",
        CorporateDocuments: "Corporate Documents",
        BRMQuestion: "Question",
        Requests: "Requests",
        BRMPrograms: "Programs",
        RulesAndPolicies: "Rules and Policies",
        MyBusinessProfile: "My Business Profile",
        Referrals: "Referrals",
        PayrollReport: "Payroll Report",
        LaborRelations: "Labor Relations",
        AttractionAndSelection: "Attraction and Selection",
        NewDevelopments: "Development requests",
        AIDevelopmentRequests: "AI Development Requests",
        WorkforceRequests: "Workforce Management",
        ShiftGrid: "Shift Grid",
        InformationSecurity: "Information Security",
        UserUpdate: "User Update",
        Logout: "Logout",
    },
    navBar:{
        language: "Language",
        noNotificationsAvailable: "No notifications available",
        recentNotifications: "Recent notifications",
        viewAll: "View all",
        notificationNumber: "Notification number",
        noFrequentAppsFound: "No frequent apps found",
        loading: "Loading..."
    },
    home: {
        recognitions: "Recognitions",
        OtherNews: "Other news",
        toDoToday: "What would you like to do today?",
        buttonSeeMore: "See more",
    },
    indexNews: {
        birthday: "Today is someone's birthday:",
        noBirthday: "No one is celebrating a birthday today!",
        BRMFlash: "Flash",
        buttonSeeMore: "See more",
    },
    newsGrid:{
        news: "News",
        buttonSeeMore: "See more",
    },
    NewsDetails:{
        buttonSeeMore: "See more",
    },
    flash:{
        buttonClose:"Close"
    },
    actionToday:{
        index: {
            reportSick: "Report sick leave", 
            requestPermission: "Request permission", 
            requestVacation: "Request vacation", 
            requestCertificates: "Request certificates", 
            viewProfile: "View profile", 
            viewSurveys: "View surveys",
          },
        buttonSeeMore: "See more",
    },
    podcast:{
        title: "Podcast",
        play: "Play",
        buttonClose:"Close"
    },
    apps: {
        title: "Applications",
        all: "All",
        favorite: "Favorites",
        AplicativosGoogle: "Google Apps",
        AplicativosBRM: "Apps",
        AplicativosCliente: "Client Apps",
        Reportes: "Reports",
        AplicativosInteligenciaArtificial: "Artificial Intelligence Apps",
        AplicativosExternos: "External Applications",
        AplicativosMicrosoft: "Microsoft Applications",
        AplicativosdeMensajeria: "Messaging Applications",
        search: "Search",
    },
    profile: {
        title: "My business profile",
        buttonEdit: "Edit",
        updateData: "Update data",
        generateCard: "Generate ID card",
        workedMonths: "Months worked",
        daysToBirthday: "Days until your birthday",
        myReferrals: "My referrals",
        indexTab: {
            personnel: "Personal",
            contact: "Contact",
            contractual: "Contractual",
            followUp: "Follow-up"
        },
        personal: {
            personalInformation: "Personal information",
            fullNames: "Full names",
            lastNames: "Last names",
            documentType: "Document type",
            documentNumber: "Document number",
            documentIssuanceDate: "Document issuance date",
            birthDate: "Birthdate",
            gender: "Gender",
            age: "Age",
            maritalStatus: "Marital status",
            academicLevel: "Academic level",
            healthPromotingEntity: "Health Promoting Entity (EPS)",
            pensionFund: "Pension fund"
        },
        contact: {
            contactInformation: "Contact information",
            department: "Department",
            city: "City",
            locality: "Locality",
            neighborhood: "Neighborhood",
            address: "Address",
            personalEmail: "Personal email",
            socioeconomicStratum: "Socioeconomic stratum",
            housingType: "Housing type",
            phone: "Phone",
            landline: "Landline"
        },
        contractual: {
            contractualInformation: "Contractual information",
            position: "Position",
            positionType: "Position type",
            status: "Status",
            corporateEmail: "Corporate email",
            contractType: "Contract type",
            entryDate: "Entry date",
            retirementDate: "Retirement date",
            contractor: "Contractor",
            costCenter: "Cost center",
            subCostCenter: "Sub cost center",
            client: "Client",
            campaign: "Campaign",
            headquarters: "Headquarters",
            headquartersCity: "Headquarters city"
        },
        followUp: {
            trackingInformation: "Tracking information",
            noTrackingInformation: "No tracking information"
        },
        update:{
            dataUpdate: "DATA UPDATE",
            businessProfile: "My business profile",
            personalInformation: "Personal information",
            firstName: "First name",
            lastName: "Last name",
            fullName: "Full name",
            documentType: "Document type",
            documentNumber: "Document number",
            documentIssuanceDate: "Document issuance date",
            birthDate: "Birthdate",
            age: "Age",
            gender: "Gender",
            maritalStatus: "Marital status",
            academicLevel: "Academic level",
            healthPromotingEntity: "Health Promoting Entity (EPS)",
            pensionFund: "Pension fund",
            contactInformation: "Contact information",
            personalEmail: "Personal email",
            mobile: "Mobile",
            phone: "Phone",
            socioeconomicStratum: "Socioeconomic stratum",
            housingType: "Housing type",
            department: "Department",
            city: "City",
            locality: "Locality",
            neighborhood: "Neighborhood",
            addressInformation: "Address information",
            address: "Address",
            dianAddress: "DIAN address",
            nomenclature: "Nomenclature",
            additionalInformation: "Additional information",
            LIMPIAR: "CLEAR",
            AGREGAR: "ADD",
            editInformation: "EDIT INFORMATION"
        },
        popUp:{
            updateYourData: "UPDATE YOUR DATA!",
            updateDescription: "Updating your personal data allows you to have accurate and up-to-date information, protect your security, make your life more comfortable, and save time.",
            updateInstruction: "To update your information in Okan, click the button ",
            updateText:"'Update Data'.",
            close: "Close",
            changeImage: "Change image",
            fileRestriction: "Only files in the following formats are allowed: png, jpeg, and bmp.",
            save: "Save",
            chooseProfilePhoto: "Choose a profile picture",
        }
    },
    general:{
        loading: "Loading..."
    }
};