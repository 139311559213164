<template>
  <div>
    <!-- DESKTOP -->
    <div class="document-details__desktop" v-if="!isMobile">
      <v-row justify="center">
        <v-col class="mt-14 pl-11 pr-11 text-left" cols="12">
          <h1 class="document-details__title">{{ documentName }}</h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12 pt-0" class="text-color px-10">
          <h2>FIRMA DE DOCUMENTO</h2>
        </v-col>
        <v-col cols="12" md="10" xs="10" sm="10" lg="7" xl="7" class="px-10">
          <p class="text-info-1 text-left">
            <strong> Pasos para la firma del documento: </strong>
          </p>
          <p class="text-info-1 text-left">
            <strong> 1. </strong> Lea con atención el siguiente documento.
          </p>
          <p class="text-info-1 text-left">
            <strong> 2. </strong> En la parte inferior de esta página firme el
            documento en el <strong>RECUADRO AZUL</strong>.
          </p>
          <p class="text-info-1 text-left">
            <strong> 3. </strong> Si no presenta una correcta visualización del
            documento por favor haga clic
            <v-btn
              @click="download"
              class="pl-5 pr-5 mr-2 ml-2 document-details__button"
              small
              rounded
              style="color: #fff"
              color="#466BE3"
            >
              aquí
            </v-btn>
            se generará una nueva ventana en el navegador o se descarga en su
            dispositivo. Una vez finalice la lectura, vuelva a esta página y
            firme en el <strong>RECUADRO AZUL</strong>.
          </p>
          <p class="text-info-1 text-left">
            <strong> 4. </strong> Preferiblemente genere la firma del documento
            desde un dispositivo móvil para una mayor facilidad en su firma.
          </p>
          <p class="text-info-1 text-left">
            <strong> 5. </strong> Finalmente de clic en guardar, para registrar
            la firma.
          </p>
          <p class="text-info-1 text-left">
            <strong> 6. </strong> El documento cuenta con una marca de agua
            ("documento solo de lectura"), una vez firmado, esta marca de agua
            desaparece.
          </p>

          <p class="text-info-1 text-left">
            <strong>
              ¡Recuerde que para visualizar la fima debe aceptar terminos y
              condiciones!
            </strong>
          </p>
          <p class="text-info-1 text-left" v-if="sendEmail">
            <strong> NOTA: </strong> Al momento de finalizar se enviará un
            correo electrónico al mail registrado en Mr Chispa con la copia del
            documento.
          </p>
        </v-col>
      </v-row>

      <div class="app-content">
        <vue-pdf-embed
          ref="pdfRef"
          :source="pdfSource"
          :page="page"
          @rendered="handleDocumentRender"
        />
      </div>

      <v-row justify="center">
        <transition name="slide-fade">
          <v-card
            elevation="0"
            class="document-details__card pa-6 slide-bottom"
            outlined
            v-if="validateTerms"
          >
            <v-card-title class="pb-0 px-0">
              <v-checkbox
                v-model="disabledDesktop"
                :label="'Acepto las condiciones consignadas en el documento relacionado anteriormente'"
                color="#3957B9"
                class="mt-0 px-0 document-details__check"
              ></v-checkbox>
            </v-card-title>
            <v-card-actions class="px-0">
              <v-btn
                @click="
                  checkboxDesktop
                    ? (checkboxDesktop = false)
                    : (checkboxDesktop = true);
                  validateTerms = false;
                "
                class="pl-5 pr-5 mr-2 ml-2 document-details__button"
                rounded
                style="color: #fff"
                color="#466BE3"
                :disabled="!disabledDesktop"
                block
              >
                <strong> Firmar </strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </transition>
      </v-row>

      <v-row justify="center" class="mx-0">
        <template v-if="checkboxDesktop">
          <v-col
            cols="12"
            md="10"
            xs="10"
            sm="10"
            lg="7"
            xl="7"
            class="px-10 mx-0"
          >
            <p class="text-info-1 text-left">
              <strong> Seleccione firma: </strong>
              <v-btn v-if="!showSign" x-small outlined @click="showSign = true">
                Generar nueva firma
              </v-btn>
            </p>
            <v-alert v-if="showAlert" dense text type="info" class="mt-3">
              No se encontró una firma, por favor genere una nueva en el
              <strong>RECUADRO AZUL</strong>
            </v-alert>
            <img
              style="width: 100%"
              :src="imgSign.signature"
              aalt="Firma del colaborador"
            />
          </v-col>
        </template>
      </v-row>
      <template v-if="showSign && checkboxDesktop">
        <v-row justify="center" class="mx-0">
          <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
            <v-btn block outlined small color="#3957B9" @click="undoDesk"
              >Deshacer</v-btn
            >
          </v-col>
          <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
            <v-btn outlined color="#3957B9" block small @click="clearDesk"
              >limpiar</v-btn
            >
          </v-col>
        </v-row>
        <v-row justify="center" class="mx-0">
          <v-col class="pt-3 mx-0" md="10" xs="10" sm="10" lg="6" xl="6">
            <vueSignature1
              class="document-details__sign"
              ref="signature1"
              :sigOption="option"
              :h="'150px'"
              :disabled="disabled"
            ></vueSignature1>
          </v-col>
        </v-row>
      </template>
      
      <v-row justify="center" class="mb-15 pb-10" v-if="checkboxDesktop">
        <v-col cols="12" md="10" xs="10" sm="10" lg="6" xl="6">
          <v-layout justify-end="">
            <v-btn
              :loading="loading"
              class="document-details__button"
              block=""
              @click="saveDesk()"
              rounded
              style="color: #fff"
              color="#466BE3"
            >
              Guardar
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </div>

    <!-- MOBILE -->
    <div class="document-details__mobile pb-10" v-else>
      <v-row class="px-0 mx-0 mt-4">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="document-details__name-text txt-capitalize"
              >
                {{ name }},
              </label>
              <label
                style="padding-top: 5%"
                class="document-details__name-text"
              >
                acá encontrará
              </label>
            </p>
          </div>
          <p
            style="text-align: left"
            class="document-details__name-subtext mb-1 ml-9"
          >
            Todos los documentos corporativos
          </p>
        </v-col>
        <v-col cols="12">
          <v-col cols="12 pt-0" class="text-color">
            <h2>FIRMA DE DOCUMENTO</h2>
          </v-col>
          <v-col cols="12" md="12" xs="12" sm="12" class="pb-0">
            <p class="text-info-1 text-left">
              <strong> Pasos para la firma del documento: </strong>
            </p>
            <p class="text-info-1 text-left">
              <strong> 1. </strong> Lea con atención el siguiente documento.
            </p>
            <p class="text-info-1 text-left">
              <strong> 2. </strong> En la parte inferior de esta página firme el
              documento en el <strong>RECUADRO AZUL</strong>.
            </p>
            <p class="text-info-1 text-left">
              <strong> 3. </strong> Si no presenta una correcta visualización
              del documento por favor haga clic
              <v-btn
                @click="download"
                class="pl-5 pr-5 mr-2 ml-2 document-details__button"
                small
                rounded
                style="color: #fff"
                color="#466BE3"
              >
                <strong> aquí </strong>
              </v-btn>
              se generará una nueva ventana en el navegador o se descarga en su
              dispositivo. Una vez finalice la lectura, vuelva a esta página y
              firme en el <strong>RECUADRO AZUL</strong>.
            </p>
            <p class="text-info-1 text-left">
              <strong> 4. </strong> Preferiblemente genere la firma del
              documento desde un dispositivo móvil para una mayor facilidad en
              su firma.
            </p>
            <p class="text-info-1 text-left">
              <strong> 5. </strong> Finalmente de clic en guardar, para
              registrar la firma.
            </p>
            <p class="text-info-1 text-left">
              <strong> 6. </strong> El documento cuenta con una marca de agua
              ("documento solo de lectura"), una vez firmado, esta marca de agua
              desaparece.
            </p>
            <p class="text-info-1 text-left">
              <strong>
                ¡Recuerde que para visualizar la fima debe aceptar terminos y
                condiciones!
              </strong>
            </p>
            <p class="text-info-1 text-left mb-0" v-if="sendEmail">
              <strong> NOTA: </strong> Al momento de finalizar se enviará un
              correo electrónico al mail registrado en Mr Chispa con la copia
              del documento.
            </p>
          </v-col>
        </v-col>

        <v-col cols="12">
          <div class="app-content">
            <vue-pdf-embed
              ref="pdfRef"
              :source="pdfSource"
              :page="page"
              @rendered="handleDocumentRender"
            />
          </div>

          <v-row justify="center">
            <transition name="slide-fade">
              <v-card
                elevation="0"
                class="document-details__card pa-6 slide-bottom mb-14"
                outlined
                v-if="validateTerms"
              >
                <v-card-title class="pb-0 px-0">
                  <v-checkbox
                    v-model="disabledDesktop"
                    :label="'Acepto las condiciones consignadas en el documento relacionado anteriormente'"
                    color="#3957B9"
                    class="mt-0 px-0 document-details__check"
                  ></v-checkbox>
                </v-card-title>
                <v-card-actions class="px-0">
                  <v-btn
                    @click="
                      checkboxMobile
                        ? (checkboxMobile = false)
                        : (checkboxMobile = true);
                      validateTerms = false;
                    "
                    class="pl-5 pr-5 mr-2 ml-2 document-details__button"
                    rounded
                    style="color: #fff"
                    color="#466BE3"
                    :disabled="!disabledDesktop"
                    block
                  >
                    <strong> Firmar </strong>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </transition>
          </v-row>

          <v-row justify="center" class="mx-0">
            <v-col cols="12" class="mx-0" v-if="checkboxMobile">
              <p class="text-info-1 text-left">
                <strong> Seleccione firma: </strong>
                <v-btn
                  v-if="!showSign"
                  x-small
                  outlined
                  @click="showSign = true"
                >
                  Generar nueva firma
                </v-btn>
              </p>
              <v-alert v-if="showAlert" dense text type="info" class="mt-3">
                No se encontró una firma, por favor genere una nueva en el
                <strong>RECUADRO AZUL</strong>
              </v-alert>
              <img
                style="width: 100%"
                :src="imgSign.signature"
                aalt="Firma del colaborador"
              />
            </v-col>
          </v-row>

          <template v-if="showSign && checkboxMobile">
            <v-row justify="center" class="mx-0">
              <v-col
                class="pb-0 mx-0"
                cols="10"
                md="3"
                xs="3"
                sm="3"
                lg="3"
                xl="3"
              >
                <v-btn block outlined small color="#3957B9" @click="undo"
                  >Deshacer</v-btn
                >
              </v-col>
              <v-col
                class="pb-0 mx-0"
                cols="10"
                md="3"
                xs="3"
                sm="3"
                lg="3"
                xl="3"
              >
                <v-btn outlined color="#3957B9" block small @click="clear"
                  >limpiar</v-btn
                >
              </v-col>
            </v-row>
            <v-row justify="center" class="mx-0">
              <v-col class="pt-3 mx-0" md="10" xs="10" sm="10" lg="6" xl="6">
                <vueSignature
                  class="document-details__sign"
                  ref="signature"
                  :sigOption="option"
                  :h="'150px'"
                  :disabled="disabled"
                ></vueSignature>
              </v-col>
            </v-row>
          </template>

          <v-row justify="center" class="mb-15 pb-10" v-if="checkboxMobile">
            <v-col cols="12" md="10" xs="10" sm="10" lg="6" xl="6">
              <v-layout justify-end="">
                <v-btn
                  :loading="loading"
                  class="document-details__button"
                  block=""
                  @click="save()"
                  rounded
                  style="color: #fff"
                  color="#466BE3"
                >
                  Guardar
                </v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="sign"
      persistent
      max-width="490"
      content-class="document-details__scroll-y-sign"
    >
      <v-card>
        <v-card-title class="text-h5 document-details__project-sign">
          <v-layout justify-center> GUARDAR FIRMA </v-layout>
        </v-card-title>
        <v-card-text class="document-details__txt-project-sign">
          Desea guardar la firma para una próxima ocasión
        </v-card-text>
        <v-card-actions>
          <v-row class="document-details__saveSign">
            <v-col cols="12" md="6">
              <v-btn rounded outlined block @click="close()"
                >No registrar firma</v-btn
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                rounded
                outlined
                block
                @click="saveSign()"
                :loading="buttonLoading"
                >Registrar firma</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.newPassword" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center>
            AGREGAR CONTRASEÑA NUEVA PARA LOS DOCUMENTO
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0" style="text-align: initial">
            * Esta contraseña será usada para otros documentos que requieran una
            mayor seguridad.
          </p>
          <p class="mb-0" style="text-align: initial">
            * Si es la primera vez que ingresa, recuerde que la contraseña es su
            número de documento, para conrinuar actualice su contraseña.
          </p>
          <v-form ref="form">
            <v-text-field
              color="#466be3"
              v-model="formPass.password_current"
              label="Contraseña actual"
              @click:append="show2 = !show2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>
            <v-text-field
              color="#466be3"
              v-model="formPass.password"
              label="Nueva contraseña"
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>
            <v-text-field
              color="#466be3"
              v-model="formPass.confirmation_password"
              label="Confirmar nueva contraseña"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                style="background-color: #fff"
                color="#466be3"
                outlined
                rounded
                block
                @click="preview"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                class="document-details__button"
                style="color: #fff"
                color="#466BE3"
                rounded
                block
                @click="updatePass()"
              >
                ACTUALIZAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.password" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center> CONTRASEÑA PARA EL DOCUMENTO </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0">Este documento requiere contraseña</p>
          <v-form ref="form">
            <v-text-field
              color="#466be3"
              v-model="formPass.password"
              label="Ingrese aquí su contraseña"
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
            ></v-text-field>

            <v-btn
              color="#466be3"
              text
              @click="(dialog.reset = true), (dialog.password = false)"
            >
              <u> Resetear contraseña </u>
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                style="background-color: #fff"
                color="#466be3"
                outlined
                rounded
                block
                @click="preview"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                @click="getPdf()"
                class="document-details__button"
                style="color: #fff"
                color="#466BE3"
                rounded
                block
              >
                Validar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.reset" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center>
            RESETEAR CONTRASEÑA DE DOCUMENTOS
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0 mt-3">
            Tenga en cuenta que si decide restaurar su contraseña, ésta se
            actualizará a su número de identificación. Por motivos de seguridad
            le recomendamos que actualice su contraseña por una más segura.
            Además, se enviará una notificación al correo electrónico para
            informarle sobre el cambio en su cuenta.
          </p>

          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expiration_date"
                label="Fecha de expedición"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="expiration_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                style="background-color: #fff"
                color="#466be3"
                outlined
                rounded
                block
                @click="(dialog.password = true), (dialog.reset = false)"
              >
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                @click="resetPass()"
                class="document-details__button"
                style="color: #fff"
                color="#466BE3"
                rounded
                block
              >
                RESETEAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import vueSignature from "vue-signature";
import vueSignature1 from "vue-signature";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import Alert from "../../components/Alert.vue";
import Api from "@/utils/api";
export default {
  name: "app",
  components: {
    vueSignature,
    vueSignature1,
    VuePdfEmbed,
    Alert,
  },
  data() {
    return {
      expiration_date: "",
      menu2: false,
      dialog: {
        password: false,
        newPassword: false,
        reset: false,
      },
      formPass: {
        password: "",
        confirmation_password: "",
        password_current: "",
      },
      show: false,
      show1: false,
      show2: false,
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      loadingAll: false,
      loading: false,
      page: null,
      pageCount: 1,
      pdfSource: "",
      showAllPages: true,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
        fontWeight: 0,
      },
      disabled: false,
      infoToken: {
        document: "",
      },
      name: "",
      token: "",
      imgSign: false,
      showAlert: false,
      showSign: false,
      sign: false,
      buttonLoading: false,
      documentName: "",
      sendEmail: false,
      validateTerms: true,
      checkboxDesktop: false,
      checkboxMobile: false,
      disabledDesktop: false,
      disabledMobile: false,
      isMobile: false
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    resetPass() {
      var data = {
        expiration_date: this.expiration_date,
      };
      Api.Auth()
        .changePasswordDocument(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.dialog.reset = false;
            this.dialog.newPassword = true;

            this.alert.txt = res.data.message;
            this.alert.title = "Excelente";
            this.alert.type = "success";
            this.alert.redirect = "";
          }
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.type = "error";
          this.alert.txt = error.response.data.message;
          this.alert.title = "¡Oh no!";
          this.alert.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    preview() {
      this.$router.push({ name: "documents" });
    },
    open(e) {
      console.log(e);
    },
    download() {
      const link = document.createElement("a");
      link.href = this.pdfSource;
      link.target = "_blank";
      link.download =
        "documentos-brm-" + new Date().toISOString().substr(0, 10) + ".pdf";
      link.click();
    },
    handleDocumentRender() {
      this.loadingAll = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },

    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },

    getPdf() {
      var data = {
        documentSignId: this.$route.params.id,
        document_user_id: this.$route.params.docUser,
        manager_password: this.formPass.password,
      };
      this.loadingAll = true;
      Api.noAuth()
        .getDocumentReference(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.pdfSource = res.data.data;
            this.dialog.password = false;
            this.dialog.newPassword = false;
            this.alert.open = true;
            this.alert.txt = res.data.message;
            this.alert.title = "Excelente";
            this.alert.type = "success";
            this.alert.redirect = "";
          }
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.type = "error";
          this.alert.txt = error.response.data.message;
          this.alert.title = "¡Oh no!";
          this.alert.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    save() {
      var _this = this;
      let png;
      let data;
      let flag = false;
      if (_this.$refs.signature && !_this.$refs.signature1.isEmpty()) {
        png = _this.$refs.signature.save();
        data = {
          documentSignId: this.$route.params.id,
          document_user_id: this.$route.params.docUser,
          img: png,
          manager_password: this.formPass.password,
        };
        flag = true;
      } else {
        png = _this.imgSign.signature;
        data = new FormData();
        data.append("documentSignId", this.$route.params.id);
        data.append("document_user_id", this.$route.params.docUser);
        data.append("manager_password", this.formPass.password);
        data.append("img", png);
      }
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      this.loadingAll = true;
      if (this.checkboxDesktop || this.checkboxMobile) {
        Api.Auth()
          .saveSign(data, config)
          .then((res) => {
            this.loadingAll = false;
            if (flag) {
              this.sign = true;
            } else {
              this.getDocumentSign(res.data.message);
            }
          })
          .catch((error) => {
            this.loadingAll = false;
            this.alert.open = true;
            this.alert.title = "¡Oh no!";
            this.alert.txt = "Intenta mas tarde...";
            this.alert.type = "error";
            this.alert.redirect = "";
          });
      }
    },

    saveDesk() {
      var _this = this;
      let png;
      let data;
      let flag = false;
      if (_this.$refs.signature1 && !_this.$refs.signature1.isEmpty()) {
        png = _this.$refs.signature1.save();
        data = {
          documentSignId: this.$route.params.id,
          document_user_id: this.$route.params.docUser,
          img: png,
          manager_password: this.formPass.password,
        };
        flag = true;
      } else {
        png = _this.imgSign.signature;
        data = new FormData();
        data.append("documentSignId", this.$route.params.id);
        data.append("document_user_id", this.$route.params.docUser);
        data.append("manager_password", this.formPass.password);
        data.append("img", png);
      }
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      if (this.checkboxDesktop || this.checkboxMobile) {
        this.loadingAll = true;
        Api.Auth()
          .saveSign(data, config)
          .then((res) => {
            this.loadingAll = false;
            if (flag) {
              this.sign = true;
            } else {
              this.getDocumentSign(res.data.message);
            }
          })
          .catch((error) => {
            this.loadingAll = false;
            this.alert.open = true;
            this.alert.title = "¡Oh no!";
            this.alert.txt = "Intenta mas tarde...";
            this.alert.type = "error";
            this.alert.redirect = "";
          });
      }
    },

    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },
    clearDesk() {
      var _this = this;
      _this.$refs.signature1.clear();
    },
    undoDesk() {
      var _this = this;
      _this.$refs.signature1.undo();
    },
    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
    getCommissionsImg() {
      this.loadingAll = true;
      Api.MarginCommisions()
        .getSign(this.token)
        .then((res) => {
          this.loadingAll = false;
          this.imgSign = res.data.data;
          if (this.imgSign != null) {
            this.showSign = false;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          if (error.response.status == 404) {
            this.showSign = true;
            this.showAlert = true;
          }
        });
    },
    close() {
      this.sign = false;
      this.getDocumentSign("El documento se firmó correctamente");
    },
    saveSign() {
      var _this = this;
      var png = null;
      if (screen.width >= 1023) {
        png = _this.$refs.signature1.save("image/png");
      } else {
        png = _this.$refs.signature.save("image/png");
      }
      this.buttonLoading = true;
      fetch(png)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "firma.png", { type: "image/png" });
          const formData = new FormData();
          formData.append("signature", file);
          formData.append("manager_password", this.formPass.password);
          this.loadingAll = true;
          Api.MarginCommisions()
            .saveSign(formData, this.token)
            .then((res) => {
              this.loadingAll = false;
              this.buttonLoading = false;
              // this.alert.open = true;
              // this.alert.title = "Excelente";
              // this.alert.txt = res.data.message;
              // this.alert.type = "success";
              // this.alert.redirect = "documents";
              this.getDocumentSign(res.data.message);
            })
            .catch((error) => {
              this.loadingAll = false;
              this.alert.open = true;
              this.alert.title = "¡Oh no!";
              this.alert.txt = "Intenta mas tarde...";
              this.alert.type = "error";
              this.alert.redirect = "";
            });
        });
    },
    getDocument() {
      var data = {
        documentId: this.$route.params.id,
      };
      this.loadingAll = true;
      Api.noAuth()
        .getDocumentById(data, this.token)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            this.sendEmail = res.data.data.document_sign.send_duva;
            this.documentName = res.data.data.document_sign.nombre;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
          console.log(error);
        });
    },
    getDocumentSign(message) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      let documents = {
        completed: null,
        pending: null,
      };
      Api.Auth()
        .signCompleted(config)
        .then((res) => {
          if (res.data.cod == 0) {
            console.log("Se va ejecutar dis");
            this.$store.dispatch("updateDocuments", documents);
            this.alert.open = true;
            this.alert.title = "Excelente";
            this.alert.txt = message;
            this.alert.type = "success";
            this.alert.redirect = "documents";
            documents.completed = res.data.data;
            Api.Auth()
              .signPending(config)
              .then((res) => {
                if (res.data.cod == 0) {
                  documents.pending = res.data.data;
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    validatePass(origin) {
      this.loadingAll = true;
      var data = {
        document_id: this.$route.params.id,
        document_user_id: this.$route.params.docUser,
      };
      if (origin == 1) {
        data.manager_password = this.formPass.password;
      }

      Api.Auth()
        .validatePass(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            if (res.data.data.password_change_required) {
              this.dialog.newPassword = true;
            } else if (res.data.data.password_required) {
              if (res.data.data.url == null) {
                this.dialog.password = true;
              }
            } else {
              this.dialog.password = false;
              this.dialog.newPassword = false;
              this.getPdf();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    updatePass() {
      this.loadingAll = true;
      var data = {
        current_password: this.formPass.password_current,
        new_password: this.formPass.password,
        confirm_password: this.formPass.confirmation_password,
      };
      Api.Auth()
        .updatePass(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.getPdf();
            this.alert.open = true;
            this.alert.txt = res.data.message;
            this.alert.title = "Excelente";
            this.alert.type = "success";
            this.alert.redirect = "";
            this.dialog.password = false;
            this.dialog.newPassword = false;
          }
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.type = "error";
          this.alert.txt = error.response.data.message;
          this.alert.title = "¡Oh no!";
          this.alert.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
  },
  created() {
    this.getToken();
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.getCommissionsImg();
    this.getDocument();
    this.validatePass(null);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.document-details__sign {
  border: 1.3px solid;
  border-color: #3a57b9;
}

.document-details__project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.document-details__txt_title_dialog {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #353535;
  word-break: break-word;
}

.document-details__txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.document-details__scroll-y-sign {
  box-shadow: none !important;
  overflow-y: inherit !important;
}

.document-details__title {
  word-break: break-word;
}

.document-details__desktop {
  display: block;
}

.document-details__mobile {
  display: none;
}

.document-details__name-text {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.document-details__name-subtext {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.theme--light.v-label {
  color: #353535;
  line-height: 1.5;
}

.document-details__display-hidden {
  display: none;
}

.document-details__display-visible {
  display: block;
}

.document-details__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.document-details__button:hover {
  background-color: #324ca1 !important;
}

.document-details__check {
  font-family: "RobotoRegular";
}

.document-details__card {
  font-family: "RobotoRegular";
  border-radius: 15px !important;
  position: fixed;
  bottom: 20px;
  z-index: 100;
}

.document-details__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

/* LAPTOP 4k */

@media (max-width: 2000px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .document-details__desktop {
    display: none;
  }

  .document-details__mobile {
    display: block;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .document-details__saveSign {
    flex-direction: column;
    align-items: center;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
