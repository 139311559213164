<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left" cols="6">
          <h1 class="reference__title">Referidos</h1>
        </v-col>
      </v-row>
      <v-card outlined class="reference__card px-8 mt-8" elevation="0">
        <!-- Filtros -->
        <v-row class="mt-2">
          <v-col cols="6">
            <v-autocomplete
              :items="listStatus"
              label="Estado"
              v-model="statusId"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="150"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Seleccionar rango de fechas"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range :max="maxDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false,dates=[],getReference()">
                  Cancelar 
                </v-btn>
                <v-btn text color="primary" @click="searchForDate()">
                  Buscar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="search" label="Buscar">
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="download()"
                block
                rounded
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
                class="mt-2 ml-2"
              >
                Descargar
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
        <!-- Tabla -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :search="search"
              :loading="loading.table"
              :items-per-page="15"
              style="background-color: #f9f9f900"
              :headers="headers"
              :items="items"
              item-key="name"
              class="elevation-0 reference__table"
              :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
            >
              <!-- Sin resultados de busqueda -->
              <template v-slot:no-results>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontraron resultados</b>
                </v-alert>
              </template>
              <!-- Sin Data -->
              <template class="pl-0 pr-0" v-slot:no-data>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontró información.</b>
                </v-alert>
              </template>
              <!-- Loading -->
              <template v-slot:progress>
                <v-progress-linear
                  color="#214484"
                  :height="5"
                  indeterminate
                  stream
                ></v-progress-linear>
              </template>

              <template v-slot:item.img_referrer="{ item }">
                <div class="float-left mt-3">
                  <img
                    style="border-radius: 120px; border: 1.3px solid #466be3"
                    width="32"
                    height="32x"
                    :src="item.img_referrer"
                  />
                </div>
              </template>

              <!-- Nombre referidor -->

              <template v-slot:item.document_number_referrer="{ item }">
                <v-tooltip max-width="300" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <p class="mb-0 mt-2 text-1-1-lines">
                        <strong> {{ item.full_name_referrer }} </strong>
                      </p>
                      <p class="mb-0 mb-2 text-1-1-lines">
                        {{ item.document_number_referrer }}
                      </p>
                    </div>
                  </template>
                  <span>
                    <strong>INFORMACIÓN REFERIDOR</strong><br />
                    <strong>NOMBRE: </strong> {{ item.full_name_referrer }}
                    <br />
                    <strong>DOCUMENTO: </strong>
                    {{ item.document_number_referrer }}
                  </span>
                </v-tooltip>
              </template>

              <!-- Estatus del referidos -->

              <template v-slot:item.status_id="{ item }">
                <v-tooltip max-width="300" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <p class="mb-0 mt-2 text-1-1-lines">
                        <strong v-if="item.status_id==1"> Activo</strong>
                        <strong v-if="item.status_id==2"> Inactivo</strong>
                        <strong v-if="item.status_id==8"> En proceso</strong>
                      </p>

                    </div>
                  </template>
                  <!-- <span>
                    <strong>INFORMACIÓN REFERIDOR</strong><br />
                    <strong>NOMBRE: </strong> {{ item.full_name_referrer }}
                    <br />
                    <strong>DOCUMENTO: </strong>
                    {{ item.document_number_referrer }}
                  </span> -->
                </v-tooltip>
              </template>

              <!-- Información del referido -->

              <template v-slot:item.name_referred="{ item }">
                <v-tooltip max-width="300" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <p class="mb-0 mt-2 text-1-1-lines">
                        <strong> {{ item.name_referred }} </strong>
                      </p>
                      <p class="mb-0 mb-2 text-1-1-lines">
                        {{ item.document_number_referred }}
                      </p>
                    </div>
                  </template>
                  <span>
                    <strong>INFORMACIÓN DEL REFERIDO </strong> <br />
                    <strong>NOMBRE: </strong> {{ item.name_referred }} <br />
                    <strong>DOCUMENTO: </strong>
                    {{ item.document_number_referred }}
                  </span>
                </v-tooltip>
              </template>

              <!-- Información de contacto del referido -->

              <template v-slot:item.document_number_referred="{ item }">
                <v-tooltip max-width="350" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <p class="mb-0 mt-2 text-1-1-lines">
                        <strong> {{ item.email_referred }} </strong>
                      </p>
                      <p class="mb-0 mb-2 text-1-1-lines">
                        {{ item.phone_referred }}
                      </p>
                    </div>
                  </template>
                  <span>
                    <strong>CONTACTO DEL REFERIDO </strong> <br />
                    <strong>CORREO: </strong> {{ item.email_referred }} <br />
                    <strong>CELULAR: </strong>
                    {{ item.phone_referred }}
                  </span>
                </v-tooltip>
              </template>

              <!-- Estado -->
              <template v-slot:item.status_reference_name="{ item }">
                <v-chip
                  v-if="item.status_reference_name"
                  :color="getColorStatus(item.status_reference_name)"
                  dark
                  small
                >
                  {{ item.status_reference_name }}
                </v-chip>
              </template>

              <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
              </template>

              <template v-slot:item.status_document_name="{ item }">
                <v-chip
                  v-if="item.status_document_name"
                  :color="getColorStatus(item.status_document_name)"
                  dark
                  small
                >
                  {{ item.status_document_name }}
                </v-chip>
              </template>

              <!-- Actions -->

              <template v-slot:item.actions="{ item }">
                <v-tooltip
                  v-if="item.status_reference_name == 'Error'"
                  max-width="300"
                  color="#000"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon small> mdi-text-box-multiple-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <strong>OBSERVACIÓN: </strong> {{ item.observations }}
                  </span>
                </v-tooltip>
                <!-- <v-tooltip max-width="300" color="#000" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="goDeteils(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="blue"
                      fab
                    >
                      <v-icon> mdi-eye-outline </v-icon>
                    </v-btn>
                  </template>
                  <span> Detalles del referido </span>
                </v-tooltip> -->
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <!-- <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay> -->
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        table: false,
      },
      items: [],
      headers: [
        {
          text: "ID vacante",
          value: "vacante_id",
          align: "center",
          class: "document__header",
        },
        {
          text: "",
          value: "img_referrer",
          class: "references__header",
        },
        {
          text: "Referente / Documento",
          value: "document_number_referrer",
          class: "references__header",
        },
        {
          text: "País vacante",
          value: "pais_vacante",
          align: "center",
          class: "document__header",
        },
        {
          text: "Nombre vacante",
          value: "nombre_vacante",
          class: "document__header",
        },
        {
          text: "Estado del Referente",
          value: "status_id",
          class: "references__header",
        },
        {
          text: "Referido / Documento",
          value: "name_referred",
          class: "references__header",
        },
        {
          text: "Contacto de referido",
          value: "document_number_referred",
          class: "references__header",
        },
        {
          text: "Contratación",
          value: "status_reference_name",
          class: "references__header",
        },
        {
          text: "Bono",
          value: "status_document_name",
          class: "references__header",
        },
        {
          text: "Fecha Registro",
          value: "created_at",
          class: "references__header",
        },
        {
          text: "Observación",
          value: "actions",
          class: "references__header",
        },
        // { text: "Documento", value: "file" },
      ],
      formInfo: {
        dateExpiration: "",
      },
      listStatus: [
        {
          id: 5,
          name: "Pendiente",
        },
        {
          id: 8,
          name: "En proceso",
        },
        {
          id: 4,
          name: "Completado",
        },
        {
          id: 3,
          name: "Expirado",
        },
        {
          id: 6,
          name: "Error",
        },
      ],
      listBonus: [],
      listProfile: [],
      loadingAll: false,
      token: "",
      infoToken: "",
      name: "",
      statusId: "",
      search: "",
      preference: true,

      dates: [],
      menu: false,
      maxDate: new Date().toISOString().substr(0, 10),
    };
  },
  watch: {
    statusId: function (n) {
      this.getReference();
    },
    getAllApps: function (newCount, oldCount) {
      console.log("");
    },
    references: function (newCount, oldCount) {
      console.log("");
    },
  },
  computed: {
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      if (allApps.message) {
        this.getApps(allApps.data);
      } else {
        this.loadingAll = true;
      }
    },
    references() {
      let references = this.$store.getters.getReferences;
      if (references) {
        let personalReference = this.$store.getters.getPersonalReference;
        if (!personalReference) {
          // let image = {
          //   text: "",
          //   value: "img_referrer",
          //   sortable: false,
          //   class: "references__header",
          // };
          // let refererName = {
          //   text: "Referente / Documento",
          //   value: "refer",
          //   class: "references__header",
          // };
          // this.headers.unshift(refererName);
          // this.headers.unshift(image);
        }
        this.items = references;
        this.loadingAll = false;
      } else {
        this.loadingAll = true;
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    goDeteils(data){
      localStorage.setItem("infoReference", JSON.stringify(data));
      this.$router.push({
        name: "referenceReportDetail",
        params: { id: data.reference_id },
      });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getReference() {
      this.items = [];
      this.loadingAll = true;
      var data = {
        statusId: this.statusId,
        personalReference: this.preference,
        created_at: this.dates.join(",")
      };
      Api.LaborRelations()
        .getReference(this.token, data)
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    download() {
      var data = {
        statusId: this.statusId,
        personalReference: this.preference,
        created_at: this.dates.join(",")
      };
      this.loadingAll = true;
      Api.LaborRelations()
        .getReportReferenceDownload(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.message.dialog = true;
          if (error.response.status == 404) {
            this.message.sms =
              "Aun no se han cargado condiciones de comisiones";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          } else {
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getApps(allApps) {
      if (allApps && allApps.length !== 0) {
        for (let i = 0; i < allApps.length; i++) {
          if (allApps[i]._ID == "324") {
            this.preference = false;
          }
        }
      }
    },
    searchForDate(){
       this.menu=false; 
       this.getReference(); 
    },
    formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  },
  created() {
    this.getToken();
  },
  // mounted() {
  //   this.getReference();
  // },
};
</script>
<style>
@import "./../../assets/css/main.less";
.navidad-text{
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}
tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
